import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import "./config/i18n";
import { MonitorContextProvider } from "./context/monitor-context";
import WorkerProvider from "./context/worker-context";
import Private from "./middleware/Private";
import NewPassword from "./pages/Auth/NewPassword/NewPassword";
import PasswordForgot from "./pages/Auth/PasswordForgot/PasswordForgot";
import SignIn from "./pages/Auth/SignIn/SignIn";
import { CustomerAnalysis } from "./pages/Customer/Dashboard/Analysis";
import CustomerDashboard from "./pages/Customer/Dashboard/Dashboard";
import { CustomerDeficiencies } from "./pages/Customer/Dashboard/Deficiencies";
import { Documente } from "./pages/Customer/Dashboard/Documente";
import { Monitors } from "./pages/Customer/Dashboard/Monitors";
import AbsenceInsight from "./pages/Dashboard/AbsenceInsight";
import { Absences } from "./pages/Dashboard/Absences";
import CheckIn from "./pages/Dashboard/CheckIn";
import { ChooseProducts } from "./pages/Dashboard/ChooseProducts";
import { ChooseTechnicians } from "./pages/Dashboard/ChooseTechnicians";
import { CreateAppointment } from "./pages/Dashboard/CreateAppointment";
import { CreateDeficiency } from "./pages/Dashboard/CreateDeficiency";
import { CreateMonitor } from "./pages/Dashboard/CreateMonitor";
import { CreatePropertyArea } from "./pages/Dashboard/CreatePropertyArea";
import { CustomerInsight } from "./pages/Dashboard/CustomerInsight";
import Dashboard from "./pages/Dashboard/Dashboard";
import DeficiencyInsight from "./pages/Dashboard/DeficiencyInsight";
import { ServiceOnDeficiency } from "./pages/Dashboard/DeficiencyService";
import { DeficiencyServiceOverview } from "./pages/Dashboard/DeficiencyServiceOverview";
import { DeficiencyServiceOverviewReadonly } from "./pages/Dashboard/DeficiencyServiceOverviewReadonly";
import { IssuesService } from "./pages/Dashboard/IssuesService";
import { MaintenanceService } from "./pages/Dashboard/MaintenanceService";
import { MonitorAdjustmentDetailView } from "./pages/Dashboard/MonitorAdjustmentDetailView";
import { MonitorAdjustPosition } from "./pages/Dashboard/MonitorAdjustPosition";
import { MonitorAdjustPositionData } from "./pages/Dashboard/MonitorAdjustPositionData";
import { MonitorBoxStatus } from "./pages/Dashboard/MonitorBoxStatus";
import { MonitorEventDetailView } from "./pages/Dashboard/MonitorEventDetailView";
import { MonitorRemoval } from "./pages/Dashboard/MonitorRemoval";
import NewAbsence from "./pages/Dashboard/NewAbsence";
import { NoIssuesService } from "./pages/Dashboard/NoIssuesService";
import { PropertyAreaServicesOverview } from "./pages/Dashboard/PropertyAreaServicesOverview";
import { PropertyInsight } from "./pages/Dashboard/PropertyInsight";
import { PropertyInsightViewOnly } from "./pages/Dashboard/PropertyInsightViewOnly";
import { SelectDeficiencyTypeAndSubtype } from "./pages/Dashboard/SelectDeficiencyTypeAndSubtype";
import { SelectMonitorType } from "./pages/Dashboard/SelectMonitorType";
import { SelectPropertyArea } from "./pages/Dashboard/SelectPropertyArea";
import { ServicesCompletion } from "./pages/Dashboard/ServicesCompletion";
import { ServicesOverview } from "./pages/Dashboard/ServicesOverview";
import { ServicesOverviewReadonly } from "./pages/Dashboard/ServicesOverviewReadonly";
import { Settings } from "./pages/Dashboard/Settings";
import { Time } from "./pages/Dashboard/Time";
import { UnreachableService } from "./pages/Dashboard/UnreachableService";
import { UploadDocument } from "./pages/Dashboard/UploadDocument";
import WorkersList from "./pages/Dashboard/WorkersList";
import { RootState } from "./store";
import { onlineStatusActions } from "./store/online-status-slice";
import { ROLES } from "./store/user-slice";
import globalRouter from "./utils/globalRouter";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  // Function to handle online event
  const handleStatus = useCallback(
    (status: boolean) => {
      dispatch(onlineStatusActions.setOnline(status));
    },
    [dispatch]
  );

  useEffect(() => {
    // Add event listeners when the component mounts
    window.addEventListener("online", () => handleStatus(true));
    window.addEventListener("offline", () => handleStatus(false));

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", () => handleStatus(true));
      window.removeEventListener("offline", () => handleStatus(false));
    };
  }, [handleStatus]);

  const Home = () => {
    if (isCustomerAdmin) {
      return <CustomerDashboard />;
    } else {
      return <Dashboard />;
    }
  };

  return (
    <WorkerProvider>
      <MonitorContextProvider>
        <Routes>
          {/* Auth */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/password-reset" element={<PasswordForgot />} />
          <Route path="/neues-passwort" element={<NewPassword />} />

          {/* Dashboard */}
          <Route path="/" element={<Home />} />
          <Route
            path="/check-in"
            element={
              <Private Component={CheckIn} requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]} />
            }
          />
          <Route
            path="/coworkers"
            element={
              <Private Component={WorkersList} requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]} />
            }
          />

          {/* Appointments */}
          <Route
            path="/appointments/create"
            element={
              <Private
                Component={CreateAppointment}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          <Route
            path="/customerinsight"
            element={
              <Private
                Component={CustomerInsight}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          {/*Appointment Technicians*/}
          <Route
            path="/appointments/create/assign-technicians"
            element={
              <Private
                Component={ChooseTechnicians}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          {/* Properties */}
          <Route
            path="/properties/:appointmentId/show"
            element={<PropertyInsight />}
          />
          <Route
            path="/properties/:propertyId/viewonly"
            element={<PropertyInsightViewOnly />}
          />
          <Route path="/monitors/status" element={<MonitorBoxStatus />} />
          <Route
            path="/monitor/adjust-position"
            element={
              <Private
                Component={MonitorAdjustPosition}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />
          <Route
            path="/monitor/adjust-position-data"
            element={
              <Private
                Component={MonitorAdjustPositionData}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          <Route
            path="/monitor/remove-data"
            element={
              <Private
                Component={MonitorRemoval}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          <Route
            path="/:propertyId/documents/upload"
            element={
              <Private
                Component={UploadDocument}
                requiredRoles={[ROLES.ADMIN]}
              />
            }
          />

          {/* Services */}
          <Route
            path="/services/issues"
            element={
              <Private
                Component={IssuesService}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />
          <Route
            path="/services/no-issues"
            element={
              <Private
                Component={NoIssuesService}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />
          <Route
            path="/services/unreachable"
            element={
              <Private
                Component={UnreachableService}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />
          <Route
            path="/services/maintenance"
            element={
              <Private
                Component={MaintenanceService}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          {/* Monitor Event Detail View*/}
          <Route
            path="/events/:id/details"
            element={<MonitorEventDetailView />}
          />

          {/* Monitor Adjustment Detail View*/}
          <Route
            path="/adjustments/:id/details"
            element={<MonitorAdjustmentDetailView />}
          />

          {/* Services Overview*/}
          <Route
            path="/services/:appointmentId/services-overview"
            element={<ServicesOverview />}
          />

          {/* Property Area Services Overview*/}
          <Route
            path="/services/:appointmentId/services-overview/step2"
            element={<PropertyAreaServicesOverview />}
          />

          <Route
            path="/services/:appointmentId/services-overview/readonly"
            element={<ServicesOverviewReadonly />}
          />

          {/* Property Areas */}
          <Route
            path="/property-areas/create"
            element={<CreatePropertyArea creationType="monitor" />}
          />

          {/* Services Completion*/}
          <Route
            path="/services/:appointmentId/services-completion"
            element={
              <Private
                Component={ServicesCompletion}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          {/* Monitors */}
          <Route path="/monitors/create" element={<SelectMonitorType />} />
          <Route
            path="/monitors/create/step-2"
            element={<SelectPropertyArea creationType="monitor" />}
          />
          <Route path="/monitors/create/step-3" element={<CreateMonitor />} />

          {/* Deficiencies */}
          <Route
            path="/deficiencies/create"
            element={<SelectDeficiencyTypeAndSubtype />}
          />
          <Route
            path="/deficiencies/create/step-2"
            element={<SelectPropertyArea creationType="deficiency" />}
          />

          <Route
            path="/deficiencies/create/assign-technicians"
            element={
              <Private
                Component={ChooseTechnicians}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          <Route
            path="/deficiencies/:deficiencyId/show"
            element={<DeficiencyInsight />}
          />

          <Route
            path="/deficiencies/:deficiencyId/resolve"
            element={<ServiceOnDeficiency />}
          />

          <Route
            path="/deficiencies/:deficiencyId/overview"
            element={<DeficiencyServiceOverview />}
          />

          <Route
            path="/deficiencies/:deficiencyId/overview/readonly"
            element={<DeficiencyServiceOverviewReadonly />}
          />

          {/* Services Completion for Deficiency*/}
          <Route
            path="/services/:deficiencyId/services-completion"
            element={
              <Private
                Component={ServicesCompletion}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          {/* Property Areas Deficiency*/}
          <Route
            path="/property-areas/deficiencies/create"
            element={<CreatePropertyArea creationType="deficiency" />}
          />
          <Route
            path="/deficiencies/create/step-3"
            element={<CreateDeficiency />}
          />

          {/* Materials / Products */}
          <Route
            path="/products"
            element={
              <Private
                Component={ChooseProducts}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />

          {/* Calendar */}
          <Route
            path="/calendar"
            element={
              <Private Component={Absences} requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]} />
            }
          />
          <Route
            path="/calendar/:absenceId/show"
            element={
              <Private
                Component={AbsenceInsight}
                requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]}
              />
            }
          />
          <Route
            path="/new-absence"
            element={
              <Private Component={NewAbsence} requiredRoles={[ROLES.ADMIN, ROLES.TECHNICIAN]} />
            }
          />

          {/* Settings */}
          <Route path="/time" element={<Time />} />
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}

          {/*Customer */}
          <Route
            path="/deficiencies"
            element={
              <Private
                Component={CustomerDeficiencies}
                requiredRoles={[ROLES.CUSTOMER_ADMIN, ROLES.PROPERTY_MANAGER, ROLES.TECHNICIAN]}
              />
            }
          />

          <Route
            path="/monitors"
            element={
              <Private
                Component={Monitors}
                requiredRoles={[ROLES.CUSTOMER_ADMIN, ROLES.PROPERTY_MANAGER]}
              />
            }
          />
          <Route
            path="/analysis"
            element={
              <Private
                Component={CustomerAnalysis}
                requiredRoles={[ROLES.CUSTOMER_ADMIN, ROLES.PROPERTY_MANAGER]}
              />
            }
          />
         <Route
            path="/documente"
            element={
              <Private
                Component={Documente}
                requiredRoles={[ROLES.CUSTOMER_ADMIN, ROLES.PROPERTY_MANAGER]}
              />
            }
          />
          {/* Universal catch-all route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MonitorContextProvider>
    </WorkerProvider>
  );
};

export default App;
