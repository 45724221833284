import {
  AccessTime,
  Analytics,
  CreateNewFolder,
  Dashboard,
  EventNote,
  Settings,
} from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { useScreenSize } from "../../hooks/useScreenSize";
import { DefectIcon } from "../../icons/DefectIcon/DefectIcon";
import { DocumenteIcon } from "../../icons/DocumenteIcon/DocumenteIcon";
import { MonitorsIcon } from "../../icons/MonitorsIcon/MonitorIcon";
import { RootState } from "../../store";
import { resetState } from "../../store/appointment-slice";
import { checkIsRunning } from "../../store/clock-slice";
import { servicedMonitorsActions } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";

import Logo from "./desktop-logo.png";
import styles from "./navigation.module.css";

type NavigationLink = {
  to: string;
  component: JSX.Element;
  label: string;
};

const NAV_LINKS: NavigationLink[] = [
  { to: "/", component: <Dashboard />, label: "LastServices" },
  { to: "/time", component: <AccessTime />, label: "Time" },
  {
    to: "/customerinsight",
    component: <CreateNewFolder />,
    label: "EnterTask",
  },
  { to: "/calendar", component: <EventNote />, label: "Absences" },
  { to: "/settings", component: <Settings />, label: "Settings" },
];

const CUSTOMER_NAV_LINKS: NavigationLink[] = [
  { to: "/", component: <Dashboard />, label: "LastServices" },
  {
    to: "/deficiencies",
    component: <DefectIcon />,
    label: "Defects & Infestation",
  },
  { to: "/monitors", component: <MonitorsIcon />, label: "Monitore" },
  { to: "/analysis", component: <Analytics />, label: "Analysis" },
  { to: "/documente", component: <DocumenteIcon />, label: "Dokumente" },
  { to: "/settings", component: <Settings />, label: "Settings" },
];

export const Navigation = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const currentPage = useSelector(
    (state: RootState) => state.tabs.dashboard.url
  );

  const appointment = useSelector((state: RootState) => state.appointment);
  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );

  const [onDashboard, setOnDashboard] = useState(currentPage === "/");

  const handleDashboardNavigation = () => {
    if (!onDashboard) {
      navigate(currentPage);

      dispatch(activeTabsActions.setMonitorsStep(1));
      dispatch(activeTabsActions.setDocumentsStep(1));
      dispatch(servicedMonitorsActions.resetState());
      if (!isRunning) {
        dispatch(resetState());
      }
      dispatch(activeTabsActions.setDashbaordUrl("/"));
    } else {
      navigate(currentPage);
      dispatch(activeTabsActions.setDashbaordUrl("/"));

      navigate("/");

      setOnDashboard(true);
    }
  };

  const getNavLinkClassName = ({ isActive, isPending }: any) =>
    isPending ? styles.pending : isActive ? styles.active : "";

  const LINKS = isCustomerAdmin ? CUSTOMER_NAV_LINKS : NAV_LINKS;
  const mainLinks = isDesktop
    ? LINKS.filter((link) => link.label !== "Settings")
    : LINKS;

  const settingsLink = LINKS.find((link) => link.label === "Settings");

  return (
    <div className={styles["dashboard-navigation"]}>
      {isDesktop && (
        <img src={Logo} className={styles["dashboard-navigation__logo"]} />
      )}
      <div className={styles["dashboard-navigation-links"]}>
        {mainLinks.map(({ to, component, label }, i) => {
          if (to === "/" && isDesktop) {
            return (
              <button
                key={i}
                className={`${styles.navLinkButton} ${getNavLinkClassName}`}
                onClick={handleDashboardNavigation}
              >
                {component}
                {isDesktop && (
                  <label
                    className={styles["dashboard-navigation-links__label"]}
                  >
                    {t(label)}
                  </label>
                )}
              </button>
            );
          }

          return (
            <NavLink key={i} to={to} className={getNavLinkClassName}>
              {component}
              {isDesktop && (
                <label className={styles["dashboard-navigation-links__label"]}>
                  {t(label)}
                </label>
              )}
            </NavLink>
          );
        })}
      </div>
      {isDesktop && settingsLink && (
        <div
          className={`${styles["dashboard-navigation-links"]} ${styles["dashboard-navigation-settings"]}`}
        >
          <NavLink to={settingsLink.to} className={getNavLinkClassName}>
            {settingsLink.component}
            <label className={styles["dashboard-navigation-links__label"]}>
              {t(settingsLink.label)}
            </label>
          </NavLink>
        </div>
      )}
    </div>
  );
};
