import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import JobService from "../../api/JobService";
import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { MaterialsList } from "../../components/MaterialsList/MaterialsList";
import Modal from "../../components/Modal/Modal";
import NoProductDocumentsModal from "../../components/Modal/NoProductDocumentsModal";
import { NoDocumentation } from "../../components/NoDocumentation/NoDocumentation";
import { PropertyAreaMonitors } from "../../components/PropertyAreaMonitors/PropertyAreaMonitors";
import { TechniciansList } from "../../components/TechniciansList/TechniciansList";
import { TimeTrackingOverview } from "../../components/TimeTrackingOverview/TimeTrackingOverview";
import { useDocumentation } from "../../hooks/useDocumentation";
import { useDownloadFiles } from "../../hooks/useDownloadFiles";
import { useScreenSize } from "../../hooks/useScreenSize";
import { useUnauthorizedAccess } from "../../hooks/useUnauthorizedAccess";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { appStateActions } from "../../store/app-state-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/services-overview.module.css";
import { mapPropertyAreasWithMonitors } from "../../utils/mapPropertyAreas";

export const ServicesOverviewReadonly = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();
  const { isCustomerAdmin } = useSelector((state: RootState) => state.user);

  const [jobId, setJobId] = useState("");
  const [propertyAreaMonitors, setPropertyAreaMonitors] = useState<any[]>([]);
  const [webProducts, setWebProducts] = useState<any>(null);
  const [sevdeskProducts, setSevdeskProducts] = useState<any>(null);
  const [products, setProducts] = useState<any>(null);
  const [infestationCountPerArea, setInfestationCountPerArea] = useState<{
    [key: string]: number;
  }>({});
  const [loading, setLoading] = useState(true);

  const serviceStarted = useSelector(
    (state: RootState) => state.clock.isRunning
  );
  const servicesStep = useSelector(
    (state: RootState) => state.tabs.services.step
  );
  const isOnline = useSelector((state: RootState) => state.online.isOnline);

  const appointment = useSelector(
    (state: RootState) => state.overviewAppointment
  );

  const isEmpty = useSelector(
    (state: RootState) => state.technicianDocuments.empty
  );
  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);

  useUnauthorizedAccess(appointment);
  const { fetchDocumentation, requestLoading, noDocument, setNoDocument } =
    useDocumentation();

  const { closeModal } = useDownloadFiles();

  useEffect(() => {
    if (appointment.jobs.length > 0) {
      const copiedJobs = Array.from(appointment.jobs);
      const lastJobId = copiedJobs.pop();
      setJobId(lastJobId.id);
    }
  }, [appointment.jobs]);

  const fetchPropertyAreaMonitors = useCallback(async () => {
    if (appointment.propertyId) {
      try {
        const { data } = await PropertyService.GetProperty(
          appointment.propertyId
        );

        dispatch(appStateActions.addProperty(data));

        const propertyAreas = (data.propertyAreas || []).filter(
          (area) => Array.isArray(area.includes.monitors) && area.includes.monitors.length > 0
        );
  
        const propertyAreaIds = propertyAreas.map((area) => area.id);

        const monitorsPromises = propertyAreaIds.map((propertyAreaId) =>
          AppointmentService.GetMonitorsWithVersions(
            appointment.id,
            propertyAreaId
          )
        );

        const monitorsResponses = await Promise.all(monitorsPromises);

        const monitorsData = monitorsResponses.map((response) =>
          response.data.filter(
            (monitor: any) => monitor.versions && monitor.versions.length > 0
          )
        );

        setPropertyAreaMonitors(
          mapPropertyAreasWithMonitors(propertyAreas, monitorsData)
        );

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [dispatch, appointment.propertyId]);

  const fetchGroupedMaterialsByJob = useCallback(async () => {
    if (jobId) {
      try {
        const { data } = await JobService.GetGroupedMaterialsByJob(jobId);

        if (data.webProducts) {
          setWebProducts(
            data.webProducts.map((p: any) => ({
              id: p.item.productId,
              name: p.item.product.name,
              quantity: p.quantity,
            }))
          );
        }

        if (data.sevdeskProducts) {
          setSevdeskProducts(
            data.sevdeskProducts.map((p: any) => ({
              id: p.item.id,
              name: p.item.name,
              quantity: p.quantity,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [jobId]);

  const fetchGroupedEventsByJob = useCallback(async () => {
    if (jobId) {
      try {
        const { data } = await JobService.GetGroupedEventsByJob(jobId);

        const infestationCountMap: { [key: string]: number } = {};

        data.forEach((areaData: any) => {
          const areaLabel = areaData.label;
          const infestationEvents = areaData.events.infestation;

          if (infestationEvents) {
            infestationCountMap[areaLabel] = infestationEvents.length;
          } else {
            infestationCountMap[areaLabel] = 0;
          }
        });

        setInfestationCountPerArea(infestationCountMap);
      } catch (error) {
        console.log(error);
      }
    }
  }, [jobId]);

  useEffect(() => {
    if (isOnline) {
      fetchPropertyAreaMonitors();
      fetchGroupedMaterialsByJob();
      fetchGroupedEventsByJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchGroupedEventsByJob,
    fetchGroupedMaterialsByJob,
    fetchPropertyAreaMonitors,
    isOnline,
    appointment,
  ]);

  useEffect(() => {
    const combinedProducts = [
      ...(webProducts || []),
      ...(sevdeskProducts || []),
    ];

    setProducts(combinedProducts);
  }, [webProducts, sevdeskProducts]);

  const handleBackButtonClicked = () => {
    if (isViewOnly) {
      navigate(-1);
    }
    if (servicesStep === 1) {
      if (isCustomerAdmin) {
        navigate("/");
      } else {
        goOneStepBack(navigate);
      }
    } else {
      dispatch(activeTabsActions.setServicesStep(1));
    }
  };

  const fetchDocument = () => {
    fetchDocumentation(appointment.document?.url);
  };

  const propertyAreaMonitorsSortedByInfestationCount = useMemo(() => {
    return [...propertyAreaMonitors].sort((a, b) => {
      const infestationCountA = infestationCountPerArea[a.propertyArea] || 0;
      const infestationCountB = infestationCountPerArea[b.propertyArea] || 0;
      return infestationCountB - infestationCountA;
    });
  }, [propertyAreaMonitors, infestationCountPerArea]);

  return (
    <AppLayout hideHeader={!isCustomerAdmin}>
      <div>
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          handleFetchDocument={fetchDocument}
        />
        <AppointmentDetails isServicesOverview />

        <Modal
          isOpen={isEmpty}
          message={t("NoInformation")}
          note={"NoAvailableDocuments"}
          iconType="noInfo"
          confirmText={"Back"}
          onConfirm={closeModal}
        />

        <NoProductDocumentsModal />

        {servicesStep === 1 && loading && <LoadingSpinner loading={loading} />}

        {!loading && jobId && (
          <div className={styles["content-wrapper"]}>
            <TimeTrackingOverview appointmentId={appointment.id} />

            <TechniciansList technicians={appointment.technicians} />

            <div className={styles.materials}>
              <CustomInput
                label={t("Remark")}
                placeholder=""
                hideTick
                initialValue={appointment.comment ?? ""}
                readonly
              />
            </div>

            <div className={styles.materials}>
              <MaterialsList
                title={"Arbeits-, Material- und Präparateeinsatz"}
                data={products}
                readOnly={true}
              />
            </div>

            <div className={styles.monitors}>
              <PropertyAreaMonitors
                readonly
                monitors={propertyAreaMonitorsSortedByInfestationCount}
                startService={() => {}}
                isServiceStarted={serviceStarted}
                isServicesOverview={true}
                jobId={jobId}
                infestationCountPerArea={infestationCountPerArea}
              />
            </div>

            {!isDesktop && (
              <FloatingButtons justify="right">
                <CustomButton
                  color="gray"
                  icon="document"
                  width={48}
                  loading={requestLoading}
                  onClick={fetchDocument}
                />
              </FloatingButtons>
            )}
          </div>
        )}

        {!loading && !jobId && (
          <>
            <NoDocumentation />

            <FloatingButtons>
              <CustomButton
                color="gray"
                icon="leftarrow"
                iconPosition="left"
                width={70}
                onClick={handleBackButtonClicked}
              >
                {t("Back to overview")}
              </CustomButton>
            </FloatingButtons>
          </>
        )}
      </div>

      <Modal
        isOpen={noDocument}
        message={t("NoDocument")}
        confirmText="OK"
        iconType="alert"
        onConfirm={() => setNoDocument(false)}
      />
    </AppLayout>
  );
};
