import { East } from "@mui/icons-material";

import styles from "./list.module.css";

type ListDataItem = {
  id: string;
  name: string;
};

type ListProps = {
  title?: string | null;
  subtitle?: string | null;
  data: ListDataItem[] | [];
  onItemSelected?: any;
  hideShadow?: boolean;
};

export const List = ({
  title,
  subtitle,
  data,
  onItemSelected,
  hideShadow = false,
}: ListProps) => {
  return (
    <div className={`${styles.list} ${hideShadow ? styles["no-shadow"] : ""}`}>
      {title && <h1 className={styles["list__title"]}>{title}</h1>}
      {subtitle && <h3 className={styles["list__subtitle"]}>{subtitle}</h3>}

      <ul>
        {data.map((item: ListDataItem, i) => (
          <div
            className={styles["list__card"]}
            key={i}
            onClick={() => onItemSelected(item)}
          >
            <h2 className={styles["list__item"]}>{item.name}</h2>
            <East />
          </div>
        ))}
      </ul>
    </div>
  );
};
