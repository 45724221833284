import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { absencesSlice } from "./absences-slice";
import { adjustPinSlice } from "./adjust-pin-slice";
import { appStateSlice } from "./app-state-slice";
import { appointmentSlice } from "./appointment-slice";
import { appointmentsSlice } from "./appointments-slice";
import { clockSlice } from "./clock-slice";
import { customerDeficienciesSlice } from "./customer-deficiencies-slice";
import { creatingDeficiencySlice } from "./deficiencies-slice";
import { deficiencyServiceSlice } from "./deficiency-service-slice";
import { documentUploadSlice } from "./document-upload.slice";
import { creatingDocumentationSlice } from "./documentation-slice";
import { imageUploaderSlice } from "./image-uploader-slice";
import { creatingServiceSlice } from "./job-slice";
import { creatingMonitorSlice } from "./monitor-slice";
import { oldMonitorsSlice } from "./old-monitors-slice";
import { onlineStatusSlice } from "./online-status-slice";
import { overviewAppointmentSlice } from "./overview-appointment-slice";
import { productDocumentsSlice } from "./product-documents-slice";
import { productsSlice } from "./products-slice";
import { propertySlice } from "./property-slice";
import { searchSlice } from "./search-slice";
import { servicedMonitorSlice } from "./serviced-monitors";
import { settingsSlice } from "./settings-slice";
import { activeTabsSlice } from "./tabs-slice";
import { creatingTaskSlice } from "./task-slice";
import { technicianDocumentsSlice } from "./technician-documents-slice";
import { techniciansSlice } from "./technicians-slice";
import { userSlice } from "./user-slice";
import { workersSlice } from "./workers-slice";

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "deficiency",
    "technicians",
    "appointment",
    "monitor",
    "user",
    "servicedMonitors",
    "deficiencyService",
    "products",
    "overviewAppointment",
    "clock",
    "customerDeficiencies",
    "property",
    "adjustPin",
    "oldMonitors",
  ],
};

const rootReducer = combineReducers({
  appState: appStateSlice.reducer,
  monitor: creatingMonitorSlice.reducer,
  task: creatingTaskSlice.reducer,
  tabs: activeTabsSlice.reducer,
  appointments: appointmentsSlice.reducer,
  online: onlineStatusSlice.reducer,
  user: userSlice.reducer,
  absences: absencesSlice.reducer,
  workers: workersSlice.reducer,
  settings: settingsSlice.reducer,
  service: creatingServiceSlice.reducer,
  clock: clockSlice.reducer,
  images: imageUploaderSlice.reducer,
  deficiency: creatingDeficiencySlice.reducer,
  products: productsSlice.reducer,
  technicians: techniciansSlice.reducer,
  appointment: appointmentSlice.reducer,
  overviewAppointment: overviewAppointmentSlice.reducer,
  servicedMonitors: servicedMonitorSlice.reducer,
  deficiencyService: deficiencyServiceSlice.reducer,
  documentation: creatingDocumentationSlice.reducer,
  customerDeficiencies: customerDeficienciesSlice.reducer,
  technicianDocuments: technicianDocumentsSlice.reducer,
  productDocuments: productDocumentsSlice.reducer,
  search: searchSlice.reducer,
  property: propertySlice.reducer,
  adjustPin: adjustPinSlice.reducer,
  oldMonitors: oldMonitorsSlice.reducer,
  documentUpload: documentUploadSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export default store;
