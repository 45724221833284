import { UsePDFInstance, pdf } from "@react-pdf/renderer";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AppointmentService from "../../api/AppointmentService";
import CustomerService from "../../api/CustomerService";
import JobService from "../../api/JobService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import {
  CustomEmailInput,
  EmailProps,
} from "../../components/CustomEmailInput/CustomEmailInput";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { Info } from "../../components/Info/Info";
import Modal from "../../components/Modal/Modal";
import { PDFDocumentForm } from "../../components/PDFDocument/PDFDocumentForm";
import { SignatureCanvas } from "../../components/SignatureCanvas/SignatureCanvas";
import { useDocumentation } from "../../hooks/useDocumentation";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { clockActions } from "../../store/clock-slice";
import { deficiencyServiceActions } from "../../store/deficiency-service-slice";
import { creatingDocumentationActions } from "../../store/documentation-slice";
import { oldMonitorsActions } from "../../store/old-monitors-slice";
import { productSliceActions } from "../../store/products-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/services-completion.module.css";
import { CommunicationWay } from "../../types/communication";
import { calculateTotalTime } from "../../utils/calculateTotalTime";
import { formatWorkloadTime } from "../../utils/formatWorkloadTime";

export const ServicesCompletion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [showNoSignatureContent, setNoSignatureContent] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [emails, setEmails] = useState<EmailProps[]>([]);
  const [totalTime, setTotalTime] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const appointment = useSelector((state: RootState) => state.appointment);
  const documentation = useSelector((state: RootState) => state.documentation);
  const { savePDF, updatePDF } = useDocumentation();

  const date = moment().format("DD.MM.YYYY");
  const currentTime = moment();
  const jobId = appointment?.jobs?.slice(-1).pop().id;

  useEffect(() => {
    const appointmentSubsetData = {
      customerName: appointment.additionalData.customer.name,
      address: appointment.additionalData.property.address,
      technicians: appointment.technicians.map(({ firstName, lastName }) => ({
        firstName,
        lastName,
      })),
      typeOfAppointment: appointment.typeOfAppointment,
    };

    dispatch(
      creatingDocumentationActions.setAppointmentSubset(appointmentSubsetData)
    );
    dispatch(
      creatingDocumentationActions.setDate(moment().format("DD. MMMM YYYY"))
    );
  }, [dispatch, appointment]);

  const fetchTotalTime = async () => {
    try {
      const { totalTime } = await calculateTotalTime(appointment.id);

      setTotalTime(totalTime);
      dispatch(
        creatingDocumentationActions.setTotalTimeWorkload(
          formatWorkloadTime(totalTime)
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCustomerEmails = useCallback(async () => {
    try {
      const { data } = await CustomerService.GetCustomerCommunicationWays(
        appointment.additionalData.customer.id,
        "EMAIL"
      );

      const emailValues = data.map((communicationWay: CommunicationWay) => ({
        id: uuidv4(),
        email: communicationWay.value,
      }));

      setEmails(emailValues);
      emailValues.forEach((email: EmailProps) => {
        dispatch(creatingDocumentationActions.addEmail(email));
      });
    } catch (error) {
      console.error(error);
    }
  }, [appointment.additionalData.customer.id]);

  useEffect(() => {
    fetchTotalTime();
    fetchCustomerEmails();
  }, [appointment.id]);

  const handleNoSignatureClick = () => {
    setNoSignatureContent(true);
  };

  const returnToDashboard = async () => {
    setIsModalOpen(false);
    setLoading(true);
    await Promise.all([
      JobService.UpdateJob(jobId, { jobState: "processed" }),
      AppointmentService.UpdateAppointment(appointment.id, {
        appointmentState: "completed",
      }),
      documentation.defAppointmentId
        ? AppointmentService.UpdateAppointment(documentation.defAppointmentId, {
            appointmentState: "completed",
          })
        : Promise.resolve(),
      generatePdfDocument(),
    ])
      .then((res) => {
        Promise.all([
          dispatch(activeTabsActions.setMonitorsStep(1)),
          dispatch(activeTabsActions.setServicesStep(1)),

          dispatch(creatingDocumentationActions.resetState()),
          dispatch(deficiencyServiceActions.resetState()),
          dispatch(productSliceActions.resetState()),
          dispatch(
            oldMonitorsActions.removeMonitorsByAppointment(appointment.id)
          ),

          dispatch(clockActions.resetClock()),
        ])
          .then(() => {
            dispatch(activeTabsActions.setDashbaordUrl(""));
            navigate("/");
          })
          .catch((error) => {
            console.error("Beenden des Dienstes fehlgeschlagen", error);
          });
      })
      .catch((error) => {
        console.error(error);
        setError("Beenden des Dienstes fehlgeschlagen");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClosePopup = () => {
    if (textArea.length > 0) {
      returnToDashboard();
    } else {
      setNoSignatureContent(false);
    }
  };

  const handleTextareaChange = (note: string) => {
    setTextArea(note);
  };

  const handleNameChange = (name: string) => {
    dispatch(creatingDocumentationActions.setEmailOwner(name));
  };

  const handleEmailInputChange = useCallback(
    (email: EmailProps) => {
      dispatch(creatingDocumentationActions.addEmail(email));
    },
    [dispatch]
  );

  const handleBackButtonClicked = () => {
    goOneStepBack(navigate);
  };

  const handleBackButtonClickedOnModal = () => {
    setNoSignatureContent(false);
    setTextArea("");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveReceipt = async (instance: UsePDFInstance) => {
    if (instance.loading || !instance.url || !instance.blob) {
      return;
    }

    try {
      if (!documentation.existingReceipt) {
        await savePDF(instance, documentation.attachedTo);
      } else {
        await updatePDF(instance);
      }
    } catch (error) {
      setError("Fehler beim Speichern der PDF-Datei.");
    }
  };

  const generatePdfDocument = async () => {
    try {
      const blob = await pdf(
        <PDFDocumentForm documentation={documentation} />
      ).toBlob();

      const instance: UsePDFInstance = {
        blob,
        loading: false,
        url: "PDF document",
        error: null,
      };

      await saveReceipt(instance);
    } catch (error) {
      setError(
        "Beim Hochladen der PDF-Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <AppLayout hideHeader>
      <Modal
        isOpen={isModalOpen}
        message={t("ServiceCompletionConfirmation", {
          date: date,
          time: currentTime.format("HH:mm"),
        })}
        totalTimeTracking={t("TotalWorkingTime", {
          totalTime: formatWorkloadTime(totalTime),
        })}
        note={"EndingOfTimeMeasurement"}
        onConfirm={returnToDashboard}
        onCancel={handleCloseModal}
        cancelText={"Cancel"}
        confirmText={"Confirm"}
        backButtonShow={handleCloseModal}
      />

      <div>
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          showDocumentation={false}
        />

        <AppointmentDetails isServicesCompletion />

        <div className={styles["content-wrapper"]}>
          <div className={styles["content"]}>
            <span className={styles["title"]}>Dokumentation</span>
            <CustomInput
              label="Auftraggeber/in"
              placeholder="Verantwortlichen eintragen"
              onInputChange={handleNameChange}
              onBlur={() => {}}
              hideTick
              maxLength={50}
            />

            <SignatureCanvas />

            <span
              className={styles["no-signature"]}
              onClick={handleNoSignatureClick}
            >
              {t("No signature available")}
            </span>

            <CustomEmailInput
              initialValues={emails}
              placeholder={t("EnterEmail") ?? ""}
              onInputChange={handleEmailInputChange}
            />

            <Info type="success">{t("PDFInfo")}</Info>

            <FloatingButtons>
              <CustomButton
                color="green"
                onClick={handleOpenModal}
                width={80}
                loading={loading}
              >
                {t("CompleteService")}
              </CustomButton>
            </FloatingButtons>
          </div>
        </div>
      </div>
      {showNoSignatureContent && (
        <div className={styles["no-signature-overlay"]}>
          <BackButtonHeader
            handleBackButtonClicked={handleBackButtonClickedOnModal}
          />

          <div className={styles["no-signature-content"]}>
            <span className={styles["no-signature__title"]}>
              {t("A signature from the client is not possible")}
            </span>

            <CustomTextArea
              placeholder={t("AdditionalText")}
              color="gray"
              onTextareaChange={handleTextareaChange}
              defaultValue={textArea}
            />

            <div
              className={`${styles["button-wrapper"]} ${
                !(textArea.length > 0) && styles["button-wrapper--grayed-out"]
              }`}
            >
              <CustomButton color="green" onClick={handleClosePopup} width={60}>
                {textArea.length > 0 ? "Bestätigen" : "abbrechen"}
              </CustomButton>
            </div>
          </div>
          {!loading && error.length > 0 && <Info type="error">{error}</Info>}
        </div>
      )}
    </AppLayout>
  );
};
