import { useNavigate } from "react-router-dom";

import { CardWrapper } from "../CardWrapper/CardWrapper";
import { MonitorTypeListItem } from "../MonitorTypeListItem/MonitorTypeListItem";

import styles from "./services-overview-types.module.css";

interface Props {
  monitors: any[];
  infestationCount?: number;
}
export const MaintenanceOverview = ({ monitors, infestationCount }: Props) => {
  const navigate = useNavigate();

  const openMonitorEventDetails = (eventId: any, monitor: any) => {
    navigate(`/events/${eventId}/details`, { state: { monitor } });
  };

  return (
    <CardWrapper
      title="Pflege"
      color="gray"
      service="maintenance"
      addInfoIcon={false}
      infestationCount={infestationCount}
    >
      {monitors?.map((monitor: any, i: number) => (
        <MonitorTypeListItem key={i} onClick={() => ({})}>
          <div className={styles["list__item"]}>
            <span className={styles["list__card--monitortype__span"]}>
              {monitor.monitorType} {monitor.isTemporary ? 'T' : ''}{monitor.position}, {monitor.location}
            </span>
            <img
              src="/img/info-icon.png"
              onClick={() => openMonitorEventDetails(monitor.eventId, monitor)}
            />
          </div>
        </MonitorTypeListItem>
      ))}
    </CardWrapper>
  );
};
