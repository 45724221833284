import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import BackEndService from "../../api/BackEndService";
import { ImageProps } from "../../types/image";
import { BlockButton } from "../BlockButton/BlockButton";
import { DeleteableImage2 } from "../DeleteableImage2/DeleteableImage2";
import { Info } from "../Info/Info";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { ReadonlyImage } from "../ReadonlyImage/ReadonlyImage";

import styles from "./multi-image-uploader2.module.css";

type MultiImageUploader2Props = {
  images: ImageProps[];
  existingImages?: ImageProps[];
  onImageUpload: (arg: ImageProps) => void;
  onDeleteImage: (arg: string) => void;
  onLoadingChange?: (loading: boolean) => void;
};

export const MultiImageUploader2 = ({
  images = [],
  onImageUpload,
  onDeleteImage,
  existingImages = [],
  onLoadingChange,
}: MultiImageUploader2Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (!files || files?.length === 0) {
      return;
    }

    if (images.length + files?.length > 10) {
      setError("Sie können bis zu 10 Bilder hochladen.");
      return;
    }
    if (onLoadingChange) onLoadingChange(true);
    // Convert FileList to an array and process each file
    Array.from(files).forEach((file) => {
      // Process each file here
      const formData = new FormData();
      formData.append("uuid", uuidv4());
      formData.append("name", file.name);
      formData.append("file", file);
      setLoading(true);
      BackEndService.UploadImage(formData)
        .then((image: any) => {
          onImageUpload(image);
          setLoading(false);
          if (onLoadingChange) onLoadingChange(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          if (onLoadingChange) onLoadingChange(false);
        });
    });

    setError("");
  };

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteImage = (uuid: string) => {
    onDeleteImage(uuid);
  };

  return (
    <div className={styles["relative-container"]}>
      <div
        style={{ opacity: loading ? 0.5 : 1 }}
        className={styles["relative-container"]}
      >
        <BlockButton
          leftIcon="plus"
          rightIcon="camera"
          onClick={handleImageUpload}
          standalone={images.length === 0}
        >
          Bilder eintragen
        </BlockButton>

        <input
          type="file"
          accept="image/*,android/force-camera-workaround"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileInputChange}
          multiple
        />
      </div>

      {loading && (
        <div className={styles["loading-container"]}>
          <LoadingSpinner loading={loading} size={20} top={"0%"} />
        </div>
      )}

      <div className={styles["images-container-entire"]}>
        {existingImages.length > 0 &&
          existingImages.map((image) => (
            <div key={image.uuid} className={styles["images-container"]}>
              <ReadonlyImage image={image} />
            </div>
          ))}

        {images.length > 0 &&
          images.map((image) => (
            <div key={image.uuid} className={styles["images-container"]}>
              <DeleteableImage2
                image={image}
                onDeleteImage={() => handleDeleteImage(image.uuid)}
              />
            </div>
          ))}
      </div>
      {error && <Info type="error">{error}</Info>}
    </div>
  );
};
