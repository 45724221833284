import { t } from "i18next";
import { Fragment } from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DeficiencyService from "../../api/DeficiencyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { MaterialsList } from "../../components/MaterialsList/MaterialsList";
import NoProductDocumentsModal from "../../components/Modal/NoProductDocumentsModal";
import { ResourceImages } from "../../components/ResourceImages/ResourceImages";
import { useRedirect } from "../../hooks/useRedirect";
import { useService } from "../../hooks/useService";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { deficiencyServiceActions } from "../../store/deficiency-service-slice";
import { creatingDocumentationActions } from "../../store/documentation-slice";
import { productSliceActions } from "../../store/products-slice";
import styles from "../../styles/deficiency-service-overview.module.css";
import { Appointment } from "../../types/appointment";
import { Deficiency } from "../../types/deficiencies";
import { ImageProps } from "../../types/image";
import { PropertyArea } from "../../types/property";

type DeficiencyStateType = {
  data: Deficiency;
  includes?: {
    propertyArea?: PropertyArea[];
    appointments?: Appointment[];
    documents?: ImageProps[];
  };
};

export const DeficiencyServiceOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deficiencyId } = useParams();

  const { setDashboardUrlAndRedirect } = useRedirect();
  const [loading, setLoading] = useState(false);

  const [deficiency, setDeficiency] = useState<DeficiencyStateType | null>(
    null
  );

  const appointment = useSelector((state: RootState) => state.appointment);

  const { startService } = useService(appointment);

  const newImages = useSelector(
    (state: RootState) => state.deficiencyService.allImages
  );

  const performedActions = deficiency?.data.performedActions.join("\n");

  useEffect(() => {
    if (!deficiencyId) {
      return;
    }
    setLoading(true);
    DeficiencyService.GetDeficiency(deficiencyId)
      .then((deficiency) => {
        setDeficiency(deficiency);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [deficiencyId]);

  useEffect(() => {
    if (!deficiency || !deficiency.includes || !deficiency.includes.documents) {
      return;
    }
  }, [deficiency]);

  const products = useMemo(
    () => [
      ...(deficiency?.data.usedProducts ?? []),
      ...(deficiency?.data.sevdeskProducts ?? []),
    ],
    [deficiency]
  );
  const images = deficiency?.includes?.documents ?? [];
  const appointmentId = deficiency?.includes?.appointments?.[0]?.id ?? "";

  const filteredImages = images.filter((image: any) => {
    return newImages?.some((newImage) => newImage.name === image.fileName);
  });
  const handleTextareaChange = (note: string) => {
    dispatch(deficiencyServiceActions.setComment(note));
  };

  const handleBackButtonClicked = () => {
    startService();
    dispatch(deficiencyServiceActions.removeImages());

    const url = `/deficiencies/${deficiency?.data.id}/resolve`;
    setDashboardUrlAndRedirect(url);

    setTimeout(() => {
      startService();
    }, 0);
  };

  const handleContinueClick = () => {
    dispatch(deficiencyServiceActions.resetServiceData());
    dispatch(productSliceActions.resetState());
    dispatch(creatingDocumentationActions.setDefAppointment(appointmentId));
    dispatch(
      creatingDocumentationActions.setAttachedTo({
        type: "Incident",
        id: deficiency?.data.id ?? "",
      })
    );

    navigate(`/services/${deficiency?.data.id}/services-completion`);
  };

  return (
    <AppLayout hideHeader>
      <NoProductDocumentsModal />

      <BackButtonHeader
        handleBackButtonClicked={handleBackButtonClicked}
        showDocumentation={false}
     />
      <AppointmentDetails isDeficiencyOverview />
      {loading && <LoadingSpinner loading={loading} />}

      {!loading && (
        <>
          <div className={styles["content-wrapper"]}>
            {products.length > 0 ? (
              <MaterialsList
                title={t("Materials")}
                data={products}
                readOnly={true}
              />
            ) : null}

            <div className={styles["actions"]}>
              <h1 className={styles["actions__title"]}>{t("Actions")}</h1>
              <h5 className={styles["actions__content"]}>
                {performedActions?.split("\n").map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </h5>
            </div>

            <ResourceImages images={filteredImages} />

            <CustomTextArea
              placeholder={t("AdditionalText")}
              onTextareaChange={handleTextareaChange}
              defaultValue={deficiency?.data.comment ?? ""}
              readonly
            />
          </div>

          <FloatingButtons>
            <CustomButton color="gray" width={80} onClick={handleContinueClick}>
              {t("Continue")}
            </CustomButton>
          </FloatingButtons>
        </>
      )}
    </AppLayout>
  );
};
