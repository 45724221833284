import {
  Property,
  PropertyArea,
  PropertyAreaStoreRequestPayload,
  PropertyAreaUpdateRequestPayload,
  PropertyStoreRequestPayload,
} from "../types/property";

import ApiService from "./ApiService";

interface PropertyQueryParams {
  "filter[customerId]"?: string;
}

type GetPropertyAreaResponse = {
  data: PropertyArea;
};

type CreatePropertyAreaResponse = {
  data: PropertyArea;
};

type GetPropertyRespose = {
  data: Property;
  includes: any;
};

type GetPropertiesRespose = {
  data: Property[];
  includes: any;
};

type CreatePropertyResponse = {
  data: Property;
};

const PropertyService = {
  GetProperty: async (propertyId: string): Promise<GetPropertyRespose> => {
    return ApiService.Get(`properties/${propertyId}`, {
      include: "propertyAreas.monitors,documents",
    });
  },

  GetProperties: async (
    params: PropertyQueryParams
  ): Promise<GetPropertiesRespose> => {
    return ApiService.Get(`properties`, { ...params });
  },

  GetPropertyDeficiencies: async (
    propertyId: string
  ): Promise<GetPropertyRespose> => {
    return ApiService.Get(`properties/${propertyId}`, {
      include: "deficiencies",
    });
  },

  CreateProperty: async (
    data: PropertyStoreRequestPayload
  ): Promise<CreatePropertyResponse> => {
    return ApiService.Post("properties", data);
  },

  GetPropertyArea: async (
    propertyAreaId: string
  ): Promise<GetPropertyAreaResponse> => {
    return ApiService.Get(`property-areas/${propertyAreaId}`);
  },

  CreatePropertyArea: async (
    data: PropertyAreaStoreRequestPayload
  ): Promise<CreatePropertyAreaResponse> => {
    return ApiService.Post("property-areas", data);
  },

  UpdatePropertyArea: async (
    id: string,
    data: PropertyAreaUpdateRequestPayload
  ): Promise<CreatePropertyAreaResponse> => {
    return ApiService.Update(id, "property-areas", data);
  },
};

export default PropertyService;
