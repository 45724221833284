import { DocumentUploadSliceState } from "../store/document-upload.slice";

export const mapDocumentEdit = (
  selectedDocument: any
): DocumentUploadSliceState => ({
  fileName: selectedDocument.fileName || "",
  media: {
    id: selectedDocument.mediaId,
    uuid: selectedDocument.mediaUuid,
    name: selectedDocument.originalFileName || "",
    preview_url: "",
    size: selectedDocument.fileSize || 0,
    mime_type: selectedDocument.mimeType || "",
    extension: "",
    thumbnails: selectedDocument.thumbnails || [],
    originalUrl: selectedDocument.originalUrl || "",
  },
  documentUploadType: selectedDocument.category || "general",
  allowedRoles: selectedDocument.allowedRoles,
});
