import { MouseEventHandler } from "react";

import threeDots from "./three_dots_icon_dark.png";

type ThreeDotsIconProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const ThreeDotsIcon = ({ onClick }: ThreeDotsIconProps) => {
  return (
    <div onClick={onClick}>
      <img src={threeDots} alt="Three dots icon" />
    </div>
  );
};
