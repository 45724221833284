import { CheckCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InfestationService from "../../../api/InfestationService";
import { CustomTextArea } from "../../../components/CustomTextArea/CustomTextArea";
import { MaterialsList } from "../../../components/MaterialsList/MaterialsList";
import { ResourceImages } from "../../../components/ResourceImages/ResourceImages";
import { InfestationPest } from "../../../types/infestation";

import styles from "./serviceDetailsModal.module.css";
import { ServiceInfo } from "./ServiceInfo";

type ServiceDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  monitorDetails: any;
  service: any;
  typeOfAppointment: string;
  documents: any[];
};

export const ServiceDetailsModal = ({
  isOpen,
  onClose,
  monitorDetails,
  service,
  typeOfAppointment,
  documents,
}: ServiceDetailsModalProps) => {
  const { t } = useTranslation();
  const [infestations, setInfestations] = useState<InfestationPest[]>();

  const products = [
    ...(service?.usedProducts ?? []),
    ...(service?.sevdeskProducts ?? []),
  ];

  useEffect(() => {
    const fetchInfestation = async () => {
      if (service && service.infestationId) {
        try {
          const response = await InfestationService.GetInfestationById(
            service.infestationId
          );
          setInfestations(response.data.pests);
        } catch (error) {
          console.error("Failed to fetch infestation data:", error);
        }
      }
    };

    fetchInfestation();
  }, [service]);

  if (!isOpen) return null;
  return (
    <div className={styles["modal-overlay"]} onClick={onClose}>
      <div
        className={styles["modal-content"]}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styles["close-button"]} onClick={onClose}>
          &times;
        </button>
        <p className={styles["name"]}>
          {monitorDetails.name} {monitorDetails.number}
        </p>
        <ServiceInfo service={service} typeOfAppointment={typeOfAppointment} />
        <div className={styles["temporer"]}>
          <p className={styles["header-5"]}>Temporärer Monitor</p>
          <CheckCircle
            color={monitorDetails.isTemporary ? "success" : "disabled"}
          />
        </div>

        {service.infestation && (
          <div className={styles["typeissue"]}>
            <p className={styles["header"]}>{t("TypeIssue")}</p>

            <div className={styles["services__issue-type__buttons"]}>
              <div
                className={`${styles["services__issue-type__button"]}  ${
                  service.infestation === "baitEaten" &&
                  styles["services__issue-type__button--active"]
                }`}
              >
                {t("BaitEaten")}
              </div>

              <div
                className={`${styles["services__issue-type__button"]}  ${
                  service.infestation === "animalFound" &&
                  styles["services__issue-type__button--active"]
                } 
            `}
              >
                {t("AnimalFound")}
              </div>
            </div>
          </div>
        )}

        {service.infestationId && (
          <div className={`${styles["services__issue-type__infestation"]}`}>
            <p className={`${styles["issue-type__infestation-title"]}`}>
              {t("InfestationType")}
            </p>
            {infestations?.map((infestation: InfestationPest) => (
              <p className={`${styles["issue-type__infestation-item"]}`}>
                <span>{infestation.name}</span>
                <span>{infestation.pivot?.quantity}</span>
              </p>
            ))}
          </div>
        )}

        <div className={styles.materials}>
          <MaterialsList title={t("Materials")} data={products} readOnly />
        </div>

        <ResourceImages images={documents} />
        <CustomTextArea
          placeholder={t("AdditionalText")}
          defaultValue={service.comment ?? ""}
          onTextareaChange={() => {}}
          readonly
        />
      </div>
    </div>
  );
};
