import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "../../../styles/customer-monitors.module.css";

export const ServiceInfo = ({
  service,
  typeOfAppointment,
}: {
  service: any;
  typeOfAppointment: string;
}) => {
  const [t] = useTranslation();
  const getBackgroundClass = (text: string) => {
    switch (text) {
      case "noInfestation":
        return styles["kein-befall"];
      case "infestation":
        return styles["befall"];
      case "maintenancePerformed":
        return styles["pflege"];
      case "unreachableOrMissing":
        return styles["nicht"];
      default:
        return "";
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return moment(date).format("DD.MM.");
  };

  const capitalizeFirstLetter = (text: string): string => {
    if (!text || typeof text !== "string" || text.length === 0) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  return (
    <div className={styles["last-service-item"]}>
      <div className={styles["date"]}>{formatDate(service?.changedAt)}</div>
      <div className={styles["type"]}>
        {typeOfAppointment === "auftrag"
          ? "Bekämpfung"
          : capitalizeFirstLetter(typeOfAppointment)}
      </div>
      <div
        className={`${styles["type2"]} ${getBackgroundClass(
          service?.eventType
        )}`}
      >
        {t(capitalizeFirstLetter(service?.eventType ?? ""))}
      </div>
    </div>
  );
};
