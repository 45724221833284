import classNames from "classnames";
import { DefaultTFuncReturn } from "i18next";
import { ChangeEvent, FocusEventHandler, useEffect, useState } from "react";

import { EditIcon } from "../../icons/EditIcon/EditIcon";

import styles from "./custom-input.module.css";
import greenTick from "./green-tick.png";

type CustomInputProps = {
  label: string;
  placeholder: string;
  onInputChange?: any;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  initialValue?: string;
  hideTick?: boolean;
  maxLength?: number;
  onInputChangeLabel?: DefaultTFuncReturn;
  readonly?: boolean;
  numeric?: boolean;
  editIcon?: boolean;
};

export const CustomInput = ({
  label,
  placeholder,
  onInputChange,
  onBlur,
  initialValue = "",
  hideTick = false,
  maxLength,
  onInputChangeLabel = label,
  readonly = false,
  numeric = false,
  editIcon = false,
}: CustomInputProps) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [error, setError] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    if (numeric) {
      value = value.replace(/[^0-9.]/g, "");
    }
    // Check if maxLength is defined and value length exceeds maxLength
    if (maxLength !== undefined && value.length > maxLength) {
      setError(true);
    } else {
      setError(false);
      setInputValue(value);
      onInputChange(value);
    }
  };

  const getLabelClasses = () =>
    classNames(styles["create-floor__button"], {
      [styles["create-floor__button--error"]]: error,
      [styles["create-floor__button--valid"]]:
        !error && inputValue && !readonly,
    });

  const getGreenTickClasses = () =>
    classNames(styles["create-floor__green-tick"], {
      [styles["create-floor__green-tick--error"]]: error,
      [styles["create-floor__green-tick--filled"]]: !error,
    });

  const getClasses = () =>
    classNames(styles["create-floor__name"], {
      [styles["create-floor__name--error"]]: error,
      [styles["create-floor__name--filled"]]: !error && inputValue && !readonly,
      [styles["create-floor--readonly"]]: readonly,
    });

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <label className={getLabelClasses()}>
        {error ? (
          <span>
            Das Feld darf nicht mehr als {maxLength} Zeichen enthalten
          </span>
        ) : inputValue ? (
          <span>{onInputChangeLabel}</span>
        ) : (
          <span>{label}</span>
        )}

        {editIcon && <EditIcon />}

        {!hideTick ? (
          <img
            className={getGreenTickClasses()}
            src={greenTick}
            height={20}
            alt="Green tick"
          />
        ) : null}
      </label>

      <textarea
        readOnly={readonly}
        onChange={handleInputChange}
        className={getClasses()}
        rows={(inputValue.match(/\n/g) || []).length + 1}
        placeholder={placeholder}
        onBlur={onBlur}
        value={inputValue}
      />
    </>
  );
};
