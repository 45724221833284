import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { ClipLoader } from "react-spinners";

import { CheckIcon } from "../../icons/CheckIcon/CheckIcon";
import { ClockIcon } from "../../icons/ClockIcon/ClockIcon";
import { DefectIcon } from "../../icons/DefectIcon/DefectIcon";
import { DeleteIcon } from "../../icons/DeleteIcon/DeleteIcon";
import { DocumentIcon } from "../../icons/DocumentIcon/DocumentIcon";
import { LayersIcon } from "../../icons/LayersIcon/LayersIcon";
import { LeftArrowIcon } from "../../icons/LeftArrowIcon/LeftArrowIcon";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { PlusIcon } from "../../icons/PlusIcon/PlusIcon";
import { WrenchIcon } from "../../icons/WrenchIcon/WrenchIcon";

import styles from "./custom-button.module.css";

type ButtonWidth = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100 | 48 | 36;
type ButtonColor = "gray" | "green" | "white" | "red";
type ButtonIcon =
  | "monitor"
  | "wrench"
  | "layers"
  | "clock"
  | "check"
  | "leftarrow"
  | "plus"
  | "document"
  | "defect"
  | "delete";

type customButtonProps = {
  children?: ReactNode;
  color: ButtonColor;
  icon?: ButtonIcon;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  width?: ButtonWidth;
  loading?: boolean;
  customClass?: any;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const COLORS: Record<string, string> = {
  gray: styles["custom-button__gray"],
  green: styles["custom-button__green"],
  white: styles["custom-button__white"],
  red: styles["custom-button__red"],
};

const ICONS: Record<string, JSX.Element> = {
  monitor: <MonitorIcon />,
  layers: <LayersIcon />,
  wrench: <WrenchIcon />,
  clock: <ClockIcon />,
  check: <CheckIcon color="dark" />,
  leftarrow: <LeftArrowIcon />,
  plus: <PlusIcon color="light" />,
  document: <DocumentIcon />,
  defect: <DefectIcon color="light" />,
  delete: <DeleteIcon />
};

export const CustomButton = ({
  children,
  color,
  icon,
  iconPosition = "right",
  disabled,
  width = 50,
  loading = false,
  customClass,
  onClick,
}: customButtonProps): JSX.Element => {
  const buttonClassName = () =>
    classNames(styles["custom-button"], COLORS[color], customClass, {
      [styles["custom-button__left-icon"]]: iconPosition === "left",
      [styles["custom-button--disabled"]]: disabled || loading,
      [styles[`w-${width}`]]: width,
    });

  return (
    <button
      className={buttonClassName()}
      disabled={loading || disabled}
      onClick={onClick}
    >
      <ClipLoader color="#fff" loading={loading} size={40} />

      {!loading && (
        <>
          {icon && iconPosition === "left" && (
            <div className={styles["custom-button__icon"]}>{ICONS[icon]}</div>
          )}

          <span className="custom-button__text">{children}</span>

          {icon && iconPosition === "right" && (
            <div className={styles["custom-button__icon"]}>{ICONS[icon]}</div>
          )}
        </>
      )}
    </button>
  );
};
