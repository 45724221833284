import deleteIcon from "./remove_icon_24px_light.png";

type DeleteIconProps = {
  color?: "light";
};

const DELETE_IMAGES: Record<"light", string> = {
  light: deleteIcon,
};

export const DeleteIcon = ({ color = "light" }: DeleteIconProps) => (
  <img src={DELETE_IMAGES[color]} height={20} alt="Delete icon" />
);
