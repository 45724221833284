import { ImageProps } from "../types/image";
import { MonitorStoreRequestPayload, MonitorType } from "../types/monitor";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";
import { MonitorEvent } from "../types/service";

import ApiService from "./ApiService";

type MonitorTypesResponse = {
  data: MonitorType[];
} & ResponseMeta &
  ResponseWarningQueries;

type MonitorTypeByIdResponse = {
  data: MonitorType;
} & ResponseMeta &
  ResponseWarningQueries;

type IncludesProps = {
  documents?: ImageProps[];
  monitor?: any;
};

export type MonitorEventByIdResponse = {
  data: MonitorEvent;
  includes?: IncludesProps;
} & ResponseMeta &
  ResponseWarningQueries;

type AddMonitorResponse = { data: any } & ResponseMeta & ResponseWarningQueries;
type CreateMonitorEventResponse = { data: any } & ResponseMeta &
  ResponseWarningQueries;

const MonitorService = {
  GetMonitorTypes: async (): Promise<MonitorTypesResponse> => {
    return ApiService.Get("monitor-types", {
      sort: "label",
    });
  },
  GetMonitorTypeById: async (id: string): Promise<MonitorTypeByIdResponse> => {
    return ApiService.GetById(id, "monitor-types");
  },
  AddMonitor: async (
    data: MonitorStoreRequestPayload
  ): Promise<AddMonitorResponse> => {
    return ApiService.Post("monitors", data);
  },
  UpdateMonitor: async (
    id: string,
    data: MonitorStoreRequestPayload
  ): Promise<AddMonitorResponse> => {
    return ApiService.Update(id, "monitors", data);
  },
  CreateMonitorEvent: async (
    data: MonitorEvent
  ): Promise<CreateMonitorEventResponse> => {
    return ApiService.Post("monitor-event", data);
  },
  UpdateMonitorEvent: async (
    id: string,
    data: MonitorEvent
  ): Promise<CreateMonitorEventResponse> => {
    return ApiService.Update(id, "monitor-event", data);
  },
  GetMonitorEventById: async (
    id: string
  ): Promise<MonitorEventByIdResponse> => {
    return ApiService.Get(`monitor-event/${id}`, {
      include: "documents,monitor",
    });
  },
  GetMonitorCurrentProducts: async (id: string): Promise<any> => {
    return ApiService.Get(`monitors/${id}/current-products`);
  },
  GetMonitorLastServices: async (id: string, page: number = 1, pageSize: number = 10): Promise<any> => {
    return ApiService.Get(`monitors/${id}/last-appointments?page[number]=${page}&page[size]=${pageSize}`);
  },
 
};

export default MonitorService;
