import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { Deficiencies } from "../../components/Deficiencies/Deficiencies";
import { Documents } from "../../components/Documents/Documents";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { InfoTab } from "../../components/InfoTab/InfoTab";
import { LastServices } from "../../components/LastServices/LastServices";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { PropertyAreaMonitors } from "../../components/PropertyAreaMonitors/PropertyAreaMonitors";
import { SubMenuButton } from "../../components/SubMenuButton/SubMenuButton";
import { useHandleService } from "../../hooks/useHandleService";
import { useService } from "../../hooks/useService";
import { UploadDocumentIcon } from "../../icons/UploadDocumentIcon/UploadDocumentIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { appStateActions } from "../../store/app-state-slice";
import { resetState } from "../../store/appointment-slice";
import { checkIsRunning, clockActions } from "../../store/clock-slice";
import { creatingDeficiencyActions } from "../../store/deficiencies-slice";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { OldMonitor } from "../../store/old-monitors-slice";
import { servicedMonitorsActions } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/task-insight.module.css";
import { Monitor } from "../../types/monitor";
import { mapPropertyAreas } from "../../utils/mapPropertyAreas";

export type ButtonType =
  | "Info"
  | "LastServices"
  | "Monitors"
  | "Documents"
  | "Defects & Infestation";

export const SUB_MENU_BUTTONS: ButtonType[] = [
  "Monitors",
  "Defects & Infestation",
  "LastServices",
  "Documents",
  "Info",
];

export const PropertyInsight = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [propertyAreaMonitors, setPropertyAreaMonitors] = useState<any[]>([]);
  const [currentTab, setCurrentTab] = useState<ButtonType>("Monitors");
  const [isStartModalOpen, setIsModalOpen] = useState(false);
  const [isStopModalOpen, setIsModalStopped] = useState(false);
  const [forbiddenAccess, setForbiddenAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState("");

  const { handleStartService, handleStopService } = useHandleService(
    setIsModalOpen,
    setLoading,
    setIsModalStopped
  );

  const appointment = useSelector((state: RootState) => state.appointment);
  const currentTabState = useSelector(
    (state: RootState) => state.tabs.dashboard.tab
  );
  const documentsStep = useSelector(
    (state: RootState) => state.tabs.documents.step
  );
  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const userRoles = useSelector(
    (state: RootState) => state.user.currentUser.roles
  );
  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );

  const isOnline = useSelector((state: RootState) => state.online.isOnline);
  const monitorsStep = useSelector(
    (state: RootState) => state.tabs.monitors.step
  );
  const oldMonitors = useSelector((state: RootState) => state.oldMonitors);

  // If servicedDeficiency is not null it means deficiency service is in progress
  const servicedDeficiency = useSelector(
    (state: RootState) => state.deficiencyService.deficiency
  );
  const { stopService } = useService(appointment);

  useEffect(() => {
    if (servicedDeficiency) {
      setForbiddenAccess(true);
    }
  }, [servicedDeficiency]);

  useEffect(() => {
    setCurrentTab(currentTabState);
  }, [currentTabState]);

  // Set current page in tabs slice
  useEffect(() => {
    if (appointment.id) {
      dispatch(
        activeTabsActions.setDashbaordUrl(`/properties/${appointment.id}/show`)
      );
    }
  }, [appointment.id, dispatch]);

  useEffect(() => {
    if (appointment.jobs.length > 0) {
      const copiedJobs = Array.from(appointment.jobs);
      const lastJobId = copiedJobs.pop();
      setJobId(lastJobId.id);
    }
  }, [appointment.jobs]);

  const fetchPropertyAreaMonitors = useCallback(async () => {
    if (appointment.propertyId) {
      try {
        setLoading(true);
        const { data } = await PropertyService.GetProperty(
          appointment.propertyId
        );

        dispatch(appStateActions.addProperty(data));

        const updatedPropertyAreas = mapPropertyAreas(data).map(
          (propertyArea: any) => {
            const updatedMonitors = propertyArea.monitors.map(
              (monitor: Monitor) => {
                const oldMonitor = oldMonitors.oldMonitors.find(
                  (old: OldMonitor) => old.id === monitor.id
                );

                if (oldMonitor) {
                  if (
                    oldMonitor.action === "adjusted" &&
                    new Date(oldMonitor.createdAt) >
                      new Date(appointment.createdAt)
                  ) {
                    return {
                      ...monitor,
                      position: oldMonitor.position,
                      location: oldMonitor.location,
                    };
                  }
                }

                return monitor;
              }
            );

            const removedMonitors = oldMonitors.oldMonitors
              .filter(
                (old: OldMonitor) =>
                  old.action === "removed" &&
                  old.appointmentId === appointment.id &&
                  new Date(old.createdAt) > new Date(appointment.createdAt) &&
                  old.propertyAreaId === propertyArea.propertyAreaId
              )
              .map((removedMonitor) => ({
                id: removedMonitor.id,
                identificationNumber: "",
                isActive: false,
                position: removedMonitor.position,
                location: removedMonitor.location,
                monitorType: removedMonitor.monitorType,
                isTemporary: removedMonitor.isTemporary,
                monitorTypeId: "",
                x: 0,
                y: 0,
              }));

            // Combine updated monitors and removed monitors into one array
            return {
              ...propertyArea,
              monitors: [
                ...updatedMonitors,
                ...removedMonitors.filter((removedMonitor) =>
                  updatedMonitors.some(
                    (monitor: Monitor) =>
                      ((monitor.monitorType as unknown) as string) ===
                      removedMonitor.monitorType
                  )
                ),
              ],
            };
          }
        );

        setPropertyAreaMonitors(updatedPropertyAreas);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [appointment.propertyId, dispatch, oldMonitors]);

  /* If user has internet connection, then fetch fresh data from backend.
  Otherwise, show data stored with redux. */
  useEffect(() => {
    if (isOnline) {
      fetchPropertyAreaMonitors();
    }
  }, [appointment, fetchPropertyAreaMonitors, isOnline, location.pathname]);

  const handleBackButtonClicked = () => {
    if (currentTab === "Monitors" && monitorsStep === 2) {
      dispatch(activeTabsActions.setMonitorsStep(1));
    } else if (currentTab === "Documents" && documentsStep === 1) {
      dispatch(activeTabsActions.setDashbaordUrl(""));
      dispatch(activeTabsActions.setPreviousPath("/"));
      dispatch(activeTabsActions.setProperyInsightTab("Monitors"));
      dispatch(activeTabsActions.setMonitorsStep(1));
      navigate("/");
    } else if (currentTab === "Documents" && documentsStep === 2) {
      dispatch(activeTabsActions.setDocumentsStep(1));
    } else if (currentTab === "Documents" && documentsStep === 3) {
      dispatch(activeTabsActions.setDocumentsStep(2));
    } else {
      //returnToDashboardPage
      dispatch(activeTabsActions.setDashbaordUrl(""));

      // Clear monitors state
      dispatch(creatingMonitorActions.setInProgress(false));
      dispatch(creatingMonitorActions.setPropertyId(""));
      dispatch(creatingMonitorActions.resetState());
      if (!isRunning) {
        dispatch(resetState());
      }

      // Reset tab
      dispatch(activeTabsActions.setProperyInsightTab("Monitors"));

      dispatch(servicedMonitorsActions.resetState());
      navigate("/");
    }
  };

  const returnToDashboard = () => {
    stopService(() => {
      dispatch(creatingMonitorActions.resetState());
      dispatch(creatingMonitorActions.setInProgress(false));
      dispatch(creatingMonitorActions.setPropertyId(""));
      dispatch(creatingDeficiencyActions.resetState());
      dispatch(clockActions.resetClock());
    });

    dispatch(activeTabsActions.setDashbaordUrl(""));

    // Reset tab
    dispatch(activeTabsActions.setProperyInsightTab("Monitors"));

    dispatch(servicedMonitorsActions.resetState());
    navigate("/");
  };

  const handleCreateMonitor = () => {
    navigate(`/monitors/create`);
  };

  const handleCreateDeficiency = () => {
    if (appointment?.customerId) {
      dispatch(creatingDeficiencyActions.setCustomerId(appointment.customerId));
    }
    navigate(`/deficiencies/create`);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleStopModal = () => {
    setIsModalStopped(true);
  };

  const handleChangeTab = (tab: ButtonType) => {
    setCurrentTab(tab);
    dispatch(activeTabsActions.setDocumentsStep(1));
    dispatch(activeTabsActions.setPreviousPath("/insight"));
    dispatch(activeTabsActions.setProperyInsightTab(tab));
  };

  const goToUploadDocument = () => {
    dispatch(activeTabsActions.setDocumentsStep(1));
    dispatch(activeTabsActions.setPreviousPath("/insight"));

    if (appointment.propertyId) {
      navigate(`/${appointment.propertyId}/documents/upload`);
    }
  };

  const forbiddenAccessHandler = () => {
    dispatch(activeTabsActions.setDashbaordUrl("/"));
    navigate("/");
  };

  return (
    <AppLayout hideHeader={true}>
      <div id="scrollable-wrapper">
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          showDocumentation={false}
        />
        {documentsStep !== 3 && <AppointmentDetails />}
        <div className={styles["tabs"]}>
          {documentsStep !== 3 && (
            <div className={styles["buttons"]}>
              {SUB_MENU_BUTTONS.map((button, i) => (
                <SubMenuButton
                  currentTab={currentTab}
                  value={button}
                  onSetTab={() => handleChangeTab(button)}
                  key={i}
                />
              ))}
            </div>
          )}

          <Modal
            isOpen={forbiddenAccess}
            message={t("AnotherServiceInProgress")}
            onConfirm={forbiddenAccessHandler}
          />

          {currentTab === "Info" && <InfoTab />}

          {currentTab === "LastServices" && <LastServices />}

          {currentTab === "Documents" && (
            <>
              <Documents />
              {documentsStep !== 3 && (userRoles?.includes("admin") ?? false) && (
                <FloatingButtons justify="right">
                  <UploadDocumentIcon onClick={goToUploadDocument} />
                </FloatingButtons>
              )}
            </>
          )}

          {currentTab === "Monitors" && (
            <>
              {loading && <LoadingSpinner loading={loading} />}
              {!loading && (
                <div className={styles.monitors}>
                  {/* This should be refactored: fetching area monitors and stuff should be 
                    handled inside of PropertyAreaMonitors component. I changed handling of loading state 
                    since it created a bug where loading spinner was sometimes rendered twice. */}
                  <PropertyAreaMonitors
                    monitors={propertyAreaMonitors}
                    startService={handleOpenModal}
                    isServiceStarted={isRunning}
                    jobId={jobId}
                  />

                  <FloatingButtons>
                    <CustomButton
                      color={isRunning ? "red" : "green"}
                      icon="clock"
                      iconPosition="left"
                      onClick={isRunning ? handleStopModal : handleOpenModal}
                      width={80}
                    >
                      {isRunning ? "Service beenden" : "Service starten"}
                    </CustomButton>
                    <CustomButton
                      color="gray"
                      icon="monitor"
                      iconPosition="right"
                      onClick={handleCreateMonitor}
                      width={48}
                    />
                  </FloatingButtons>

                  {isStartModalOpen && (
                    <Modal
                      isOpen={isStartModalOpen}
                      message={t("ServiceStartModalMessage")}
                      note={"ServiceStartModalNote"}
                      onCancel={() => setIsModalOpen(false)}
                      onConfirm={handleStartService}
                    />
                  )}

                  {isStopModalOpen && (
                    <Modal
                      isOpen={isStopModalOpen}
                      message={t("ServiceStopModalMessage")}
                      onCancel={returnToDashboard}
                      onConfirm={handleStopService}
                      cancelText={"CancelText"}
                      confirmText={"ConfirmText"}
                      backButtonShow={() => setIsModalStopped(false)}
                    />
                  )}
                </div>
              )}
            </>
          )}

          {currentTab === "Defects & Infestation" && (
            <div className={styles.monitors}>
              <Deficiencies />

              <FloatingButtons>
                <CustomButton
                  color={isRunning ? "red" : "green"}
                  icon="clock"
                  iconPosition="left"
                  onClick={isRunning ? handleStopModal : handleOpenModal}
                  width={80}
                >
                  {isRunning ? "Service beenden" : "Service starten"}
                </CustomButton>

                <CustomButton
                  color="gray"
                  icon="plus"
                  onClick={handleCreateDeficiency}
                  width={48}
                />
              </FloatingButtons>

              {isStartModalOpen && (
                <Modal
                  isOpen={isStartModalOpen}
                  message={t("ServiceStartModalMessage")}
                  note={"ServiceStartModalNote"}
                  onCancel={() => setIsModalOpen(false)}
                  onConfirm={handleStartService}
                />
              )}

              {isStopModalOpen && (
                <Modal
                  isOpen={isStopModalOpen}
                  message={t("ServiceStopModalMessage")}
                  onCancel={returnToDashboard}
                  onConfirm={handleStopService}
                  cancelText={"CancelText"}
                  confirmText={"ConfirmText"}
                  backButtonShow={() => setIsModalStopped(false)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};
