import { Settings as SettingsIcon } from "@mui/icons-material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import version from "../../../package.json";
import AuthService from "../../api/AuthService";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { Info } from "../../components/Info/Info";
import { FeedbackIcon } from "../../icons/FeedbackIcon/FeedbackIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { resetState } from "../../store/appointment-slice";
import { clockActions } from "../../store/clock-slice";
import { customerDeficienciesActions } from "../../store/customer-deficiencies-slice";
import { overviewAppointmentSliceActions } from "../../store/overview-appointment-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { userSliceActions } from "../../store/user-slice";
import styles from "../../styles/settings.module.css";

export const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { isOnline } = useSelector((state: RootState) => state.online);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const RedirectToLink = () =>
    window.open("https://roadmap.pestix.de/roadmap", "_blank");

  useEffect(() => {
    if (isOnline) {
      setError("");
    }
  }, [isOnline]);

  const handleSignOut = () => {
    if (!isOnline) {
      setError(() => t("NoInternet"));
      return;
    }

    AuthService.SignOut()
      .then(() => {
        localStorage.clear();
        dispatch(userSliceActions.resetState());
        dispatch(clockActions.resetClock());
        dispatch(customerDeficienciesActions.resetState());
        dispatch(activeTabsActions.setDashbaordUrl(""));
        dispatch(overviewAppointmentSliceActions.resetState());
        dispatch(resetState());
        navigate("/");
      })
      .catch(() => {
        setError(() => t("Error"));
      });
  };

  return (
    <AppLayout>
      <div className={styles["container"]}>
        <div className={styles["settings__heading"]}>
          <p className={styles["settings__title"]}>
            {<SettingsIcon />} {t("Settings")}
          </p>
        </div>

        <div className={styles["settings-content"]}>
          {error && <Info type="error">{error}</Info>}

          <div className={styles["settings-bottom"]}>
            {isCustomerAdmin && (
              <div
                className={styles["settings-bottom__feedback-link"]}
                onClick={RedirectToLink}
              >
                <FeedbackIcon handleFeedbackClicked={() => {}} /> Roadmap
              </div>
            )}
            <FloatingButtons>
              <CustomButton color="gray" onClick={handleSignOut}>
                {t("SignOut")}
              </CustomButton>
            </FloatingButtons>
          </div>
        </div>
        <span className={styles.version}>{version.version}</span>
      </div>
    </AppLayout>
  );
};
