import { useState } from "react";

import { CircleIcon } from "../../icons/CircleIcon/CircleIcon";
import { MegaphoneIcon } from "../../icons/MegaphoneIcon/MegaphoneIcon";

import styles from "./multiselect.module.css";

type multiselectProps = {
  title: string;
  options: { value: string; label: string }[];
  onSelect: (selectedOptions: string[]) => void;
  initialSelectedOptions?: string[];
};

export const MultiselectRoles = ({
  title,
  options,
  onSelect,
  initialSelectedOptions = [],
}: multiselectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    initialSelectedOptions
  );
  
  const handleOptionClick = (option: any) => {
    setSelectedOptions((prevSelected: any) => {
      if (prevSelected.includes(option)) {
        const updatedSelection = prevSelected.filter(
          (item: any) => item !== option
        );
        onSelect(updatedSelection);
        return updatedSelection;
      } else {
        const updatedSelection = [...prevSelected, option];
        onSelect(updatedSelection);
        return updatedSelection;
      }
    });
  };

  return (
    <div className={styles["multiselect"]}>
      <div className={styles["multiselect__title"]}>
        <span>{title}</span>
        <MegaphoneIcon />
      </div>

      <div className={styles["multiselect__options"]}>
        {options.map((option, index) => (
          <div key={index} className={styles["multiselect__option"]} onClick={() => handleOptionClick(option.value)}>
            <CircleIcon isSelected={selectedOptions.includes(option.value)} />
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};
