import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import BackEndService from "../../api/BackEndService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { DocumentUploader } from "../../components/DocumentUploader/DocumentUploader";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import Modal from "../../components/Modal/Modal";
import { MultiselectRoles } from "../../components/Multiselect/MultiselectRoles";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { documentUploadActions } from "../../store/document-upload.slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { ROLES } from "../../store/user-slice";
import styles from "../../styles/upload-document.module.css";
import { DocumentUploadType } from "../../types/document";

export const UploadDocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { propertyId } = useParams();

  const [requestLoading, setRequestLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentUploadLoading, setDocumentUploadLoading] = useState(false);
  const options = [
    { value: ROLES.TECHNICIAN, label: t("Technician") },
    { value: ROLES.CUSTOMER_ADMIN, label: t("CustomerAdmin") },
    { value: ROLES.PROPERTY_MANAGER, label: t("PropertyManager") },
  ];
  const documentUpload = useSelector(
    (state: RootState) => state.documentUpload
  );

  const appointment = useSelector((state: RootState) => state.appointment);

  const previousPath = useSelector(
    (state: RootState) => state.tabs.previousPath
  );

  const [activeTab, setActiveTab] = useState<DocumentUploadType>(
    documentUpload.documentUploadType
  );

  const submittable =
    documentUpload.fileName &&
    documentUpload.media.uuid &&
    documentUpload.allowedRoles.length > 0;

  const documentId = location.state?.documentId;

  const handleNameChange = (name: string) => {
    dispatch(documentUploadActions.setFileName(name));
  };

  const handleFileUpload = (file: File) => {
    const formData = new FormData();

    formData.append("uuid", uuidv4());
    formData.append("name", file.name);
    formData.append("file", file);

    setDocumentUploadLoading(true);

    BackEndService.UploadImage(formData)
      .then((uploadedDocument: any) => {
        dispatch(
          documentUploadActions.setMedia({
            id: uploadedDocument.id,
            uuid: uploadedDocument.uuid,
            name: uploadedDocument.name,
            preview_url: uploadedDocument.preview_url,
            size: uploadedDocument.size,
            mime_type: uploadedDocument.mime_type,
            extension: uploadedDocument.extension,
            thumbnails: uploadedDocument.thumbnails,
            originalUrl: uploadedDocument.originalUrl,
          })
        );

        setDocumentUploadLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeActiveTab = (value: DocumentUploadType) => {
    setActiveTab(value);
    dispatch(documentUploadActions.setDocumentUploadType(value));
  };

  const handleRoleSelection = (selectedRoles: string[]) => {
    dispatch(documentUploadActions.setAllowedRoles(selectedRoles));
  };

  const handleBackButtonClicked = () => {
    dispatch(activeTabsActions.setProperyInsightTab("Documents"));
    if (documentId) dispatch(activeTabsActions.setDocumentsStep(3));
    else dispatch(activeTabsActions.setDocumentsStep(1));

    if (previousPath === "/viewonly" && propertyId)
      navigate(`/properties/${propertyId}/viewonly`);
    else if (previousPath === "/insight" && appointment?.id)
      navigate(`/properties/${appointment.id}/show`);

    dispatch(documentUploadActions.resetState());
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleAddDocument = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setRequestLoading(true);

      const documentPayload = {
        documentType: "userUpload",
        fileName: documentUpload.fileName,
        isInternal: true,
        media: documentUpload.media.uuid,
        category: documentUpload.documentUploadType,
        allowedRoles: documentUpload.allowedRoles,
        attachedTo: {
          type: "Property",
          id: propertyId,
        },
      };

      if (documentId) {
        BackEndService.UpdateDocument(documentId, documentPayload)
          .then(() => {
            setRequestLoading(false);
            resolve();

            dispatch(activeTabsActions.setProperyInsightTab("Documents"));
            dispatch(activeTabsActions.setDocumentsStep(2));

            if (previousPath === "/viewonly" && propertyId)
              navigate(`/properties/${propertyId}/viewonly`);
            else if (previousPath === "/insight" && appointment?.id)
              navigate(`/properties/${appointment.id}/show`);
            dispatch(documentUploadActions.resetState());
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      } else {
        BackEndService.AddDocument(documentPayload)
          .then(() => {
            setRequestLoading(false);
            resolve();

            dispatch(activeTabsActions.setProperyInsightTab("Documents"));
            dispatch(activeTabsActions.setDocumentsStep(2));

            if (previousPath === "/viewonly" && propertyId)
              navigate(`/properties/${propertyId}/viewonly`);
            else if (previousPath === "/insight" && appointment?.id)
              navigate(`/properties/${appointment.id}/show`);
            dispatch(documentUploadActions.resetState());
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      }
    });
  };

  const handleDeleteDocument = () => {
    if (!documentId) return;

    setRequestLoading(true);

    BackEndService.DeleteDocument(documentId)
      .then(() => {
        setRequestLoading(false);
        setIsModalOpen(false);
        dispatch(documentUploadActions.resetState());
        dispatch(activeTabsActions.setPreviousPath("/"));
        dispatch(activeTabsActions.setProperyInsightTab("Documents"));
        dispatch(activeTabsActions.setDocumentsStep(2));

        if (previousPath === "/viewonly")
          navigate(`/properties/${propertyId}/viewonly`);
        else if (previousPath === "/insight")
          navigate(`/properties/${appointment.id}/show`);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <AppLayout hideHeader>
      <Modal
        isOpen={isModalOpen}
        backButtonShow={() => setIsModalOpen(false)}
        iconType="remove"
        message={t("AreYouSureDocumentModal")}
        cancelText={"Cancel"}
        confirmText={"Delete"}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={handleDeleteDocument}
        closeButtonColor="#3D5170"
        confirmButtonColor="#cb3844"
      />

      <BackButtonHeader
        handleBackButtonClicked={handleBackButtonClicked}
        showDocumentation={false}
      />
      <AppointmentDetails />

      <div className={styles["content"]}>
        <SubMenu showSearchButton={false}>
          <span>Dokument erstellen</span>
        </SubMenu>

        <DocumentUploader
          label="Dokument"
          placeholder="Dokument hochladen"
          onFileSelect={handleFileUpload}
          loading={documentUploadLoading}
          {...(documentUpload.media.name && {
            initialValue: documentUpload.media.name,
          })}
        />

        <CustomInput
          label="Dokumentenname"
          placeholder="Dokument benennen"
          initialValue={documentUpload.fileName ?? ""}
          onInputChange={handleNameChange}
          onBlur={() => {}}
          maxLength={60}
          hideTick
          editIcon
        />

        <div className={styles["upload-documents__type"]}>
          <p className={styles["upload-documents-type--title"]}>
            {t("Category")}
          </p>

          <div className={styles["upload-documents__buttons"]}>
            <div
              className={`${styles["upload-documents__button"]} ${
                activeTab === "riskAssessment" &&
                styles["upload-documents__button--active"]
              }`}
              onClick={() => changeActiveTab("riskAssessment")}
            >
              {t("RiskAssessment")}
            </div>

            <div
              className={`${styles["upload-documents__button"]} ${
                activeTab === "general" &&
                styles["upload-documents__button--active"]
              }`}
              onClick={() => changeActiveTab("general")}
            >
              {t("General")}
            </div>
          </div>
        </div>

        <MultiselectRoles
          title="Freigabe"
          options={options}
          onSelect={handleRoleSelection}
          initialSelectedOptions={documentUpload.allowedRoles}
        />

        <FloatingButtons>
          {documentId && (
            <CustomButton
              color="red"
              onClick={handleOpenModal}
              icon="delete"
              width={48}
            ></CustomButton>
          )}

          <CustomButton
            color="green"
            onClick={handleAddDocument}
            width={60}
            loading={requestLoading}
            disabled={!submittable}
          >
            {t("Confirm")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
