import { useState } from "react";

import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import styles from "../../../styles/customer-monitors.module.css";

import { MonitorDetailsType } from "./Monitors";
import { ServiceDetailsModal } from "./ServiceDetailsModal";
import { ServiceInfo } from "./ServiceInfo";

type MonitorDetailsProps = {
  monitorDetails: MonitorDetailsType;
  lastServicesData: any[];
  lastServicesIncludes: any[];
  monitorProducts: any[];
  documents: any[];
  loading: boolean;
  isFetching : boolean;
};

export const MonitorDetails = ({
  monitorDetails,
  lastServicesData = [],
  lastServicesIncludes = [],
  monitorProducts = [],
  documents = [],
  loading = false,
  isFetching = false
}: MonitorDetailsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [typeOfAppointment, setTypeOfAppointment] = useState("");
  const [images, setImages] = useState<any>([]);
  const openModal = (service: any, type: string) => {
    setSelectedService(service);
    setTypeOfAppointment(type);
    setImages(
      documents?.filter((document) => document?.attachedTo?.id === service?.id)
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  return (
    <div id="monitor-details" className={styles["monitor-details"]}>
      {loading ? (
        <LoadingSpinner loading={loading} size={15}></LoadingSpinner>
      ) : (
        <>
          <p className={styles["monitor-details__p-bold"]}>
            {monitorDetails.name} {monitorDetails.isTemporary ? 'T' : ''}{monitorDetails.number},{" "}
            <span className={styles["monitor-details__p"]}>
              {monitorDetails.location}
            </span>
          </p>

          {monitorDetails.isTemporary && (
            <div className={styles["info-button"]}>Temporär</div>
          )}

          <div className={styles["loading-div"]}>
            <div>
              <p className={styles["gray-p"]}>Aktuelles Material:</p>
              {Array.isArray(monitorProducts) && monitorProducts.length > 0 ? (
                monitorProducts.map((product: any, index: number) => (
                  <div key={index} className={styles["materials-item"]}>
                    <p>{product.name}</p>
                    <p>{product.quantity}</p>
                  </div>
                ))
              ) : (
                <p>Keine Produkte verfügbar</p>
              )}
            </div>
            <div>
              <p className={styles["gray-p"]}>Letzte Kundenbesuche:</p>
              {Array.isArray(lastServicesData) &&
              lastServicesData.length > 0 ? (
                lastServicesData.map((service: any, index: number) => (
                  <div
                    key={index}
                    onClick={() =>
                      openModal(
                        service,
                        lastServicesIncludes[index]?.appointment
                          ?.typeOfAppointment
                      )
                    }
                  >
                    <ServiceInfo
                      key={index}
                      service={service}
                      typeOfAppointment={
                        lastServicesIncludes[index]?.appointment
                          ?.typeOfAppointment
                      }
                    />
                  </div>
                ))
              ) : (
                <p>Keine Kundenbesuche vorhanden</p>
              )}
              <LoadingSpinner loading = {isFetching} size={20} top="10"></LoadingSpinner>

              <ServiceDetailsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                monitorDetails={monitorDetails}
                service={selectedService}
                typeOfAppointment={typeOfAppointment}
                documents={images}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
