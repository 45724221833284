import classNames from "classnames";
import { DefaultTFuncReturn, t } from "i18next";
import { MouseEventHandler } from "react";

import { BackButtonHeader } from "../BackButtonHeader/BackButtonHeader";

import acceptIcon from "./accept-icon.png";
import alertIcon from "./alert-icon.png";
import deleteIcon from "./delete-icon.png";
import styles from "./modal.module.css";
import noInformationIcon from "./no_information_icon.png";
import removeIcon from "./remove-icon.png";

type IconType = "alert" | "noInfo" | "remove";

type ModalProps = {
  isOpen: boolean;
  message?: DefaultTFuncReturn;
  totalTimeTracking?: DefaultTFuncReturn;
  note?: string;
  cancelText?: string;
  confirmText?: string;
  iconType?: IconType;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  backButtonShow?: MouseEventHandler<HTMLImageElement>;
  deleteIconColor?: string;
  closeButtonColor?: string;
  confirmButtonColor?: string;
};

const Modal = ({
  isOpen,
  message,
  totalTimeTracking,
  note,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  iconType,
  backButtonShow,
  deleteIconColor,
  closeButtonColor = "#cb3844",
  confirmButtonColor = "#069aa9",
}: ModalProps) => {
  const buttonsClasses = classNames(styles["modal__buttons"], {
    [styles["modal__buttons--text-version"]]: cancelText && confirmText,
  });

  const closeButtonClasses = classNames(
    cancelText
      ? styles["modal__buttons--close-text"]
      : styles["modal__buttons--close"],
    {
      [styles["modal__buttons--close-dark"]]: deleteIconColor === "dark",
    }
  );

  const confirmButtonClasses = confirmText
    ? styles["modal__buttons--accept-text"]
    : styles["modal__buttons--accept"];

  const getIcon = (iconType: IconType) => {
    switch (iconType) {
      case "alert":
        return alertIcon;
      case "noInfo":
        return noInformationIcon;
      case "remove":
        return removeIcon;
      default:
        return null;
    }
  };

  return (
    <>
      {isOpen && (
        <div className={styles["modal__overlay"]}>
          {backButtonShow && (
            <BackButtonHeader
              backButtonForModal
              handleBackButtonClicked={backButtonShow}
              showDocumentation={false}
            />
          )}
          <div className={styles["modal__content"]}>
            {iconType && (
              <img
                src={getIcon(iconType) ?? undefined}
                alt="Modal icon"
                className={styles["modal__alert-icon"]}
              />
            )}
            <h2 className={styles["modal__text"]}>
              {message ? message : t("DeleteEntry")}
            </h2>

            {totalTimeTracking && (
              <h2 className={styles["modal__total"]}>{totalTimeTracking}</h2>
            )}

            {note && <h4 className={styles["modal__note"]}>{t(note)}</h4>}

            <div className={buttonsClasses}>
              {onCancel && (
                <button
                  className={closeButtonClasses}
                  onClick={onCancel}
                  style={{ background: closeButtonColor }}
                >
                  {cancelText ? (
                    <span>{t(cancelText)}</span>
                  ) : (
                    <img src={deleteIcon} alt="Delete icon" />
                  )}
                </button>
              )}

              {onConfirm && (
                <button
                  className={confirmButtonClasses}
                  onClick={onConfirm}
                  style={{ background: confirmButtonColor }}
                >
                  {confirmText ? (
                    <span>{t(confirmText)}</span>
                  ) : (
                    <img src={acceptIcon} alt="Accept icon" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
