import selected from "./selected_circle_icon_16px.png";
import unselected from "./unselected_circle_icon_16px.png";

type CircleIconProps = {
  isSelected: boolean;
};

export const CircleIcon = ({ isSelected }: CircleIconProps) => (
  <img
    src={isSelected ? selected : unselected}
    alt={isSelected ? "Selected circle icon" : "Unselected circle icon"}
  />
);
