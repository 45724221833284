import { MouseEventHandler } from "react";

import document from "./download.png";

type DownloadDocumentProps = {
  handleDownloadButtonClicked: MouseEventHandler<HTMLImageElement>;
};

export const DownloadDocument = ({
  handleDownloadButtonClicked,
}: DownloadDocumentProps) => (
  <img
    src={document}
    alt="Download icon"
    onClick={handleDownloadButtonClicked}
  />
);
