import L, {
  LatLngBoundsExpression,
  LatLngExpression,
  Marker as MarkerType,
} from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { Marker, useMap } from "react-leaflet";

import { Document } from "../../types/document";
import { Crop, MonitorPinAbbr } from "../../types/map";

import "leaflet/dist/leaflet.css";
import styles from "./pin-crop-map.module.css";

interface MapContentProps {
  imageHeight: number;
  imageWidth: number;
  propertyMap: Document;
  monitorPinAbbr: MonitorPinAbbr | MonitorPinAbbr[];
  crop: Crop | Crop[];
  isDraggable?: boolean;
  zoomLevel: number;
  onCropChange: (crop: Crop) => void;
  onPinClick?: (index: number) => void;
  isPinSelected?: boolean;
}

const MapContent = (props: MapContentProps) => {
  const map = useMap();
  const pinRef = useRef<MarkerType>(null);

  const {
    imageHeight,
    imageWidth,
    propertyMap,
    monitorPinAbbr,
    crop,
    isDraggable,
    zoomLevel,
    onCropChange,
    onPinClick,
    isPinSelected,
  } = props;

  const [position, setPosition] = useState<LatLngExpression>(
    Array.isArray(crop) ? [crop[0].x, crop[0].y] : [crop.x, crop.y]
  );

  const [selectedPinIndex, setSelectedPinIndex] = useState<number | null>(null);

  let bounds: LatLngBoundsExpression = [
    [0, 0],
    [imageHeight, imageWidth],
  ];

  const imageOverlay = L.imageOverlay(propertyMap.url, bounds).addTo(map);
  imageOverlay?.on("load", () => {
    if (zoomLevel === map.getMinZoom()) return;

    map.invalidateSize(true);
    map.setMinZoom(zoomLevel);
    map.setZoom(zoomLevel);
  });

  const handlePinClick = (index: number) => {
    setSelectedPinIndex(index);
    if (onPinClick) {
      onPinClick(index);
    }
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = pinRef.current;
        if (marker != null) {
          const pos = marker.getLatLng();
          onCropChange({ x: pos.lat, y: pos.lng });
        }
      },
    }),
    [onCropChange]
  );
  const generateMarkerIcon = (abbr: MonitorPinAbbr, isSelected: boolean) => {
    if (abbr.name === "" && abbr.number === "") return null;
    const pinClassName = `${styles["map-pin"]} ${
      isSelected && styles["selected"]
    }`;

    const iconSize = isSelected ? L.point(50, 50) : L.point(45, 45);
    const iconAnchor = L.point(22, 53);

    return new L.DivIcon({
      html: `
        <div class="${pinClassName}">
        <p class=${styles["pin-name"]}>${abbr.name}</p>
        <p class=${styles["pin-number"]}>${abbr.number}</p>
        </div>
        `,
      className: styles["pin-container"],
      iconSize,
      iconAnchor,
    });
  };

  useEffect(() => {
    if (Array.isArray(crop)) {
      setPosition([crop[0].x, crop[0].y]);
    } else {
      setPosition([crop.x, crop.y]);
    }
  }, [crop]);

  useEffect(() => {
    if (isPinSelected) {
      setSelectedPinIndex(0);
    }
  }, []);

  if (!Array.isArray(crop)) {
    const icon = generateMarkerIcon(monitorPinAbbr as MonitorPinAbbr, false);
    if (!icon) return null;

    return (
      <Marker
        draggable={isDraggable ?? true}
        eventHandlers={eventHandlers}
        position={position}
        ref={pinRef}
        icon={icon}
      />
    );
  }

  return (
    <>
      {crop.map((singleCrop, index) => {
        const isSelected = index === selectedPinIndex;
        const icon = generateMarkerIcon(
          (monitorPinAbbr as MonitorPinAbbr[])[index],
          isSelected
        );

        if (!icon) return null;

        const markerEventHandlers = {
          click: () => handlePinClick(index),
        };

        return (
          <Marker
            key={index}
            position={[singleCrop.x, singleCrop.y]}
            icon={icon}
            draggable={false}
            eventHandlers={markerEventHandlers}
          />
        );
      })}
    </>
  );
};

export default MapContent;
