import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import InfestationService from "../../api/InfestationService";
import MonitorService, {
  MonitorEventByIdResponse,
} from "../../api/MonitorService";
import PropertyService from "../../api/PropertyService";
import {AppointmentDetails} from "../../components/AppointmentDetails/AppointmentDetails";
import {BackButtonHeader} from "../../components/BackButtonHeader/BackButtonHeader";
import {CustomButton} from "../../components/CustomButton/CustomButton";
import {CustomCollapseWithChildren} from "../../components/CustomCollapseWithChildren/CustomCollapseWithChildren";
import {InfestationDetails} from "../../components/EventDetails/InfestationDetails";
import {MaintenanceDetails} from "../../components/EventDetails/MaintenanceDetails";
import {NoInfestationDetails} from "../../components/EventDetails/NoInfestationDetails";
import {UnreachableDetails} from "../../components/EventDetails/UnreachableDetails";
import {FloatingButtons} from "../../components/FloatingButtons/FloatingButtons";
import {LoadingSpinner} from "../../components/LoadingSpinner/LoadingSpinner";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import {ServiceInfoBox} from "../../components/ServiceInfoBox/ServiceInfoBox";
import {AppLayout} from "../../layout/AppLayout/AppLayout";
import styles from "../../styles/monitor-event-detail-view.module.css";
import {Document} from "../../types/document";
import {InfestationPest} from "../../types/infestation";

interface EventTypes {
  infestation: JSX.Element;
  noInfestation: JSX.Element;
  maintenancePerformed: JSX.Element;
  unreachableOrMissing: JSX.Element;
}

const typeMappings: {[key: string]: string} = {
  infestation: "Befall",
  noInfestation: "Kein Befall",
  maintenancePerformed: "Pflege",
  unreachableOrMissing: "Nicht erreichbar",
  notInspected: "Nicht kontrolliert",
};

const COLOR: {[key: string]: string} = {
  infestation: "red",
  noInfestation: "green",
  maintenancePerformed: "blue",
  unreachableOrMissing: "white",
  notInspected: "yellow",
};

export const MonitorEventDetailView = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [event, setEvent] = useState<MonitorEventByIdResponse>();
  const [infestations, setInfestations] = useState<InfestationPest[]>();
  const [propertyAreaMap, setPropertyAreaMap] = useState<Document>();
  const {id} = useParams();
  const {state} = useLocation();

  const eventTypes: EventTypes = {
    infestation: (
      <InfestationDetails event={event} infestations={infestations} />
    ),
    noInfestation: <NoInfestationDetails event={event} />,
    maintenancePerformed: <MaintenanceDetails event={event} />,
    unreachableOrMissing: <UnreachableDetails event={event} />,
  };

  useEffect(() => {
    if (id) {
      MonitorService.GetMonitorEventById(id)
        .then(async (event) => {
          setEvent(event);

          if (event.data.infestationId) {
            const response = await InfestationService.GetInfestationById(
              event.data.infestationId
            );
            setInfestations(response.data.pests);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, state?.monitor]);

  const propertyAreaId = event?.includes?.monitor[0].propertyAreaId;
  const monitorOriginalVersion = event?.data?.monitorVersions?.find(
    (version: any) => version.isOriginal === 1
  );

  useEffect(() => {
    if (propertyAreaId) {
      PropertyService.GetPropertyArea(propertyAreaId)
        .then((propertyArea: any) => {
          if (propertyArea?.data.map) {
            setPropertyAreaMap(propertyArea.data.map);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [propertyAreaId, setPropertyAreaMap]);

  const handleBackButtonClicked = () => {
    navigate(-1);
  };

  const renderEventDetails = () => {
    return event?.data?.eventType ? (
      eventTypes[event?.data?.eventType as keyof typeof eventTypes]
    ) : (
      <LoadingSpinner loading />
    );
  };

  const crop = {
    x: monitorOriginalVersion?.x ?? 1,
    y: monitorOriginalVersion?.y ?? 1,
  };

  const monitorPinAbbr = {
    name: event?.includes?.monitor[0]?.referenceNumber
      .slice(0, 3)
      .toUpperCase(),
    number: monitorOriginalVersion != null ? (event?.includes?.monitor[0]?.isTemporary ? "T" : '') + monitorOriginalVersion?.position : "001",
  };

  return (
    <AppLayout hideHeader>
      <BackButtonHeader
        handleBackButtonClicked={handleBackButtonClicked}
        showDocumentation={false}
      />
      <AppointmentDetails />

      <div className={styles["content-wrapper"]}>
        <ServiceInfoBox
          data={state.monitor}
          typeOfService={typeMappings[event?.data?.eventType ?? ""] || ""}
          color={COLOR[event?.data?.eventType ?? ""] || "white"}
          hideCheck
        />

        <div className={styles["map"]}>
          <CustomCollapseWithChildren
            active={!!propertyAreaMap?.url}
            collapsedByDefault
            rightIcon="collapseArrow"
            label={t("Map")}
            defaultText={t("NoMapAvailable")}
          >
            <div className={styles["map-container"]}>
              {propertyAreaMap && (
                <PinCropMap
                  map={propertyAreaMap}
                  crop={crop}
                  monitorPinAbbr={monitorPinAbbr}
                  onCropChange={() => { }}
                  isDraggable={false}
                  zoom={false}
                />
              )}
            </div>
          </CustomCollapseWithChildren>
        </div>
        {renderEventDetails()}
      </div>
      <FloatingButtons>
        <CustomButton
          color="gray"
          icon="leftarrow"
          iconPosition="left"
          width={70}
          onClick={handleBackButtonClicked}
        >
          {t("Back to overview")}
        </CustomButton>
      </FloatingButtons>
    </AppLayout>
  );
};
