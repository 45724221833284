import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackEndService from "../../api/BackEndService";
import PropertyService from "../../api/PropertyService";
import { generateUUIDFromID } from "../../services/UUIDGeneratorService";
import { RootState } from "../../store";
import { propertyActions } from "../../store/property-slice";
import { searchActions } from "../../store/search-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { creatingTaskActions } from "../../store/task-slice";
import { Info } from "../Info/Info";
import { List } from "../List/List";
import PullToRefresh from "../PullToRefresh/PullToRefresh";

import styles from "./task-form.module.css";

export const StepTwo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(3);
  const searchValue = useSelector((state: RootState) => state.search.value);
  const [filteredProperties, setFilteredProperties] = useState<any[]>([]);
  const task = useSelector((state: RootState) => state.task);

  useEffect(() => {
    if (task?.customer?.properties?.length === 0) {
      const redirectTimeout = setTimeout(() => {
        dispatch(creatingTaskActions.resetAppointment());
        dispatch(activeTabsActions.setAppointmentsStep(1));
      }, 3000);

      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      // Clean up the timeout and interval to avoid memory leaks
      return () => {
        clearTimeout(redirectTimeout);
        clearInterval(interval);
      };
    }
  }, [dispatch, task?.customer?.properties?.length]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      setFilteredProperties(
        task.customer.properties.filter((object: any) =>
          `${object.street} ${object.zip ?? object.postcode} ${object.city}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredProperties(task.customer.properties);
    }
  }, [searchValue, task.customer.properties]);

  const onSelectObject = (object: any) => {
    dispatch(creatingTaskActions.setProperty({ property: object }));
    dispatch(
      creatingTaskActions.setAdditionalDataProperty({
        property: {
          id: object.id,
          address: object.name,
          isSevdeskProperty: object.isSevdeskProperty,
        },
      })
    );

    dispatch(searchActions.setIsExpanded(false));
    dispatch(searchActions.setSearchValue(""));
    const isUUID = (id: string) =>
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        id
      );

    const propertyId = isUUID(object.id?.toString()!)
      ? object.id
      : generateUUIDFromID(object.id?.toString()!);
    dispatch(propertyActions.setPropertyId(propertyId));
    dispatch(propertyActions.setViewOnly(true));
    dispatch(activeTabsActions.setAppointmentsStep(1));
    navigate(`/properties/${propertyId}/viewonly`);
  };

  const fetchCustomerProperties = async () => {
    try {
      const customerId = generateUUIDFromID(task.customer.id?.toString()!);

      const sevdeskProperties = await BackEndService.GetObjects(
        task.customer.id
      ); //sevdesk objects
      const properties = await PropertyService.GetProperties({
        "filter[customerId]": customerId,
      }); //backend objects

      // Set customer properties that we need to list on step two
      dispatch(
        creatingTaskActions.setCustomerProperties({
          customerProperties: [...sevdeskProperties.data, ...properties.data],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handlePullToRefresh = (callback: Function) => {
    fetchCustomerProperties()
      .then(() => {
        callback();
      })
      .catch((error) => {
        callback(error);
      });
  };

  return (
    <PullToRefresh
      className={styles["customer-refresh"]}
      onRefresh={handlePullToRefresh}
    >
      <List
        subtitle={t("ObjectSelect")}
        data={filteredProperties.map((object: any) => ({
          id: object.id.toString(),
          name: `${object.street} ${object.zip ?? object.postcode} ${
            object.city
          }`,
          isSevdeskProperty: object.isSevdeskProperty,
          postcode: object.zip ?? object.postcode,
          street: object.street,
          city: object.city,
        }))}
        onItemSelected={(object: any) => {
          onSelectObject(object);
        }}
      />
      {task.customer.properties.length === 0 && (
        <Info>No properties, redirecting in {countdown}</Info>
      )}
    </PullToRefresh>
  );
};
