import { ChangeEvent, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";

import { UploadIcon } from "../../icons/UploadIcon/UploadIcon";
import { truncateFileName } from "../../utils/truncateFileName";

import styles from "./document-uploader.module.css";

type DocumentUploaderProps = {
  label: string;
  placeholder: string;
  loading?: boolean;
  onFileSelect: (file: File) => void;
  onLoadingChange?: (loading: boolean) => void;
  initialValue?: string;
};

export const DocumentUploader = ({
  label,
  placeholder,
  loading = false,
  onFileSelect,
  initialValue
}: DocumentUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState<string>(
    initialValue ? truncateFileName(initialValue, 30) : ""
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const truncatedName = truncateFileName(file.name, 30);
      setSelectedFileName(truncatedName);
      onFileSelect(file);
    }
  };

  return (
    <div
      className={styles["document-uploader"]}
      onClick={() => document.getElementById("fileInput")?.click()}
      role="button"
    >
      <label className={styles["document-uploader__button"]}>
        <span>{label}</span>
        <UploadIcon />
      </label>
      {loading ? (
        <span className={styles["document-uploader__name"]}>
          <ClipLoader color="#11A8B8" loading={loading} size={40} />
        </span>
      ) : (
        <span
          className={`${styles["document-uploader__name"]} ${
            selectedFileName
              ? styles["document-uploader__name"]
              : styles["document-uploader__name-placeholder"]
          }`}
        >
          {selectedFileName || placeholder}
        </span>
      )}

      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};
