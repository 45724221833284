import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { List } from "../../components/List/List";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { NoData } from "../../components/NoData/NoData";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { useScreenSize } from "../../hooks/useScreenSize";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { go } from "../../services/NavigationService";
import { RootState } from "../../store";
import { creatingDeficiencyActions } from "../../store/deficiencies-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/select-floor.module.css";

type Props = {
  creationType: string;
};

export const SelectPropertyArea = ({ creationType }: Props) => {
  const [propertyAreas, setPropertyAreas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();

  const monitorTypeName = location.state?.monitorTypeName;
  const monitorId = location.state?.monitorId;
  const appointment = useSelector((state: RootState) => state.appointment);
  const deficiency = useSelector((state: RootState) => state.deficiency);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const propertyId = appointment.propertyId;

  const getProperties = async () => {
    setLoading(true);
    await PropertyService.GetProperty(propertyId)
      .then((property) => {
        setPropertyAreas(
          property.data.propertyAreas.map((propertyArea) => ({
            id: propertyArea.id,
            name: propertyArea.label,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));
    getProperties();
  }, [
    propertyId,
    appointment,
    deficiency,
    dispatch,
    location.pathname,
    monitorTypeName,
    monitorId,
  ]);

  const handleMonitorErstellenClicked = () => {};

  const handleCreatePropertyArea = () => {
    if (creationType === "monitor") {
      navigate(`/property-areas/create`, {
        state: {
          appointment: appointment,
          creationType: creationType,
          ...location.state,
        },
      });
    } else if (creationType === "deficiency") {
      navigate(`/property-areas/deficiencies/create`, {
        state: {
          creationType: creationType,
        },
      });
    }
  };

  const handlePropertyAreaSelected = (propertyArea: any) => {
    if (creationType === "monitor") {
      navigate(`/monitors/create/step-3`, {
        state: { propertyArea, monitorTypeName, monitorId, appointment },
      });
    } else if (creationType === "deficiency") {
      dispatch(creatingDeficiencyActions.setPropertyAreaId(propertyArea.id));

      navigate(`/deficiencies/create/step-3`, {
        state: { propertyArea, appointment, deficiency },
      });
    }
  };

  const handleBackButtonClicked = () => {
    if (creationType === "monitor")
      go(navigate, "/monitors/create", location.state);
    else if (creationType === "deficiency") {
      dispatch(activeTabsActions.setDeficienciesStep(2));

      go(navigate, "/deficiencies/create", location.state);
    }
  };

  return (
    <AppLayout
      hideHeader={!isCustomerAdmin || (isCustomerAdmin && !isDesktop)}
      className="calendar-wrapper-content"
    >
      <div>
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          isDeficiency={creationType === "deficiency"}
          showDocumentation={false}
        />

        <div className={styles["content-wrapper"]}>
          {(!isCustomerAdmin || (isCustomerAdmin && !isDesktop)) && (
            <AppointmentDetails />
          )}

          {loading && <LoadingSpinner loading={loading} />}

          {!loading && (
            <div className={styles["content"]}>
              {creationType === "monitor" && (
                <SubMenu showSearchButton={true}>
                  <span>{monitorTypeName} Erstellen</span>
                  <MonitorIcon color="dark" />
                </SubMenu>
              )}

              {creationType === "deficiency" && (
                <SubMenu showSearchButton={true}>
                  <span> {t("Report a defect")}</span>
                </SubMenu>
              )}

              {propertyAreas.length > 0 && (
                <List
                  title="Ebenen"
                  data={propertyAreas}
                  onItemSelected={handlePropertyAreaSelected}
                />
              )}

              {propertyAreas.length === 0 && (
                <NoData>Keine Ebene vorhanden 🤔</NoData>
              )}

              {!isCustomerAdmin && (
                <BlockButton
                  leftIcon="plus"
                  rightIcon="layers"
                  onClick={handleCreatePropertyArea}
                >
                  Ebene erstellen
                </BlockButton>
              )}
            </div>
          )}
        </div>

        {creationType === "monitor" && (
          <FloatingButtons>
            <CustomButton
              color="gray"
              icon="monitor"
              iconPosition="right"
              onClick={handleMonitorErstellenClicked}
              disabled
              width={60}
            >
              Monitor Erstellen
            </CustomButton>
          </FloatingButtons>
        )}
      </div>
    </AppLayout>
  );
};
