/* eslint-disable no-unused-vars */

import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AppointmentService from "../../api/AppointmentService";
import DeficiencyService from "../../api/DeficiencyService";
import PropertyService from "../../api/PropertyService";
import { Info } from "../../components/Info/Info";
import { RootState } from "../../store";
import { appointmentsActions } from "../../store/appointments-slice";
import { Deficiency } from "../../types/deficiencies";
import { PropertyArea } from "../../types/property";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { NoData } from "../NoData/NoData";
import PullToRefresh from "../PullToRefresh/PullToRefresh";

import CustomersView from "./CustomersView";
import TechniciansView from "./TechniciansView";

export interface GroupedDeficiencies {
  open: Deficiency[];
  resolved: Deficiency[];
}

export const Deficiencies = () => {
  const dispatch = useDispatch();
  const [deficiencies, setDeficiencies] = useState<Deficiency[]>([]);
  const [loading, setLoading] = useState(false);
  const [failedToFetchData, setFailedToFetchData] = useState(false);
  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);
  const propertyIdFromProperty = useSelector(
    (state: RootState) => state.property.id
  );
  const propertyIdFromAppointment = useSelector(
    (state: RootState) => state.appointment.propertyId
  );

  const propertyId = isViewOnly
    ? propertyIdFromProperty
    : propertyIdFromAppointment;

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  useEffect(() => {
    setLoading(true);

    const id = isCustomerAdmin ? selectedPropertyId : propertyId;

    PropertyService.GetPropertyDeficiencies(id)
      .then((result) => {
        // Find property area names in 'includes' and attach them to deficiencies
        const deficiencies = result.includes.deficiencies.map(
          (item: Deficiency) => {
            const propertyArea = result.data.propertyAreas.find(
              (include: PropertyArea) => include.id === item.propertyAreaId
            );

            return { ...item, propertyAreaLabel: propertyArea?.label };
          }
        );
        setDeficiencies(deficiencies);
        setLoading(false);
        setFailedToFetchData(false);
      })
      .catch((error) => {
        setLoading(false);
        setFailedToFetchData(true);
      });
  }, [selectedPropertyId, propertyId]);

  const handleDelete = (deficiencyId: string, appointmentId?: string) => {
    if (appointmentId) {
      AppointmentService.DeleteAppointment(appointmentId)
        .then(() => {
          if (appointmentId) {
            dispatch(appointmentsActions.removeAppointment(appointmentId));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    DeficiencyService.DeleteDeficiency(deficiencyId)
      .then(() =>
        setDeficiencies(
          deficiencies.filter((deficiency) => deficiency.id !== deficiencyId)
        )
      )
      .catch((error) => console.error(error));
  };

  const groupedDeficiencies = deficiencies.reduce<GroupedDeficiencies>(
    (groups, deficiency) => {
      if (deficiency.state === "open" || deficiency.state === "processing") {
        groups.open.push(deficiency);
      } else {
        groups.resolved.push(deficiency);
      }
      return groups;
    },
    { open: [], resolved: [] }
  );

  groupedDeficiencies.open.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  groupedDeficiencies.resolved.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <PullToRefresh onRefresh={() => {}}>
      {loading && <LoadingSpinner loading={loading} />}

      {!loading && deficiencies.length > 0 && !isCustomerAdmin && (
        <TechniciansView
          groupedDeficiencies={groupedDeficiencies}
          handleDelete={handleDelete}
        />
      )}

      {!loading && deficiencies.length > 0 && isCustomerAdmin && (
        <CustomersView
          groupedDeficiencies={groupedDeficiencies}
          handleDelete={handleDelete}
        />
      )}

      {!loading && deficiencies.length === 0 && (
        <NoData>{t("NoEntries")}</NoData>
      )}

      {!loading && failedToFetchData && (
        <Info type="error">{t("Data retrieval failed")}</Info>
      )}
    </PullToRefresh>
  );
};
