import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import MonitorService from "../../../api/MonitorService";
import PropertyService from "../../../api/PropertyService";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { NoData } from "../../../components/NoData/NoData";
import PinCropMap from "../../../components/PinCropMap/PinCropMap";
import { SubMenuButton } from "../../../components/SubMenuButton/SubMenuButton";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import { MonitorsIcon } from "../../../icons/MonitorsIcon/MonitorIcon";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import { RootState } from "../../../store";
import styles from "../../../styles/customer-monitors.module.css";
import { Document } from "../../../types/document";
import { Crop } from "../../../types/map";
import { MonitorPinAbbr } from "../../../types/map";
import { Meta } from "../../../types/response";

import { MonitorDetails } from "./MonitorDetails";

interface PropertyArea {
  id: string;
  name: string;
  map: Document | null;
  monitors: any[];
}

export type MonitorDetailsType = {
  id: string;
  name: string;
  number: string;
  location: string;
  isTemporary: boolean;
};

export type Product = {
  name: string;
  quantity: string;
};

export const Monitors = () => {
  const [propertyAreas, setPropertyAreas] = useState<any[]>([]);
  const [subMenuButtons, setSubMenuButtons] = useState<string[]>([]);
  const [map, setMap] = useState<Document | null>(null);
  const [crops, setCrops] = useState<Crop[]>([]);
  const [monitorPinAbbrs, setMonitorPinAbbrs] = useState<MonitorPinAbbr[]>([]);
  const [currentTab, setCurrentTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [message, setMessage] = useState("");
  const selectedProperty = useSelector(
    (state: RootState) => state.user?.selectedProperty?.id
  );

  const [monitorDetails, setMonitorDetails] = useState<MonitorDetailsType>({
    id: "",
    name: "",
    number: "",
    location: "",
    isTemporary: false,
  });
  const prevMonitorId = useRef<string | null>(null);

  const [selectedArea, setSelectedArea] = useState(0);
  const [openMonitorDetails, setOpenMonitorDetails] = useState(true);
  const [isPinSelected, setIsPinSelected] = useState(false);
  const [lastServicesData, setLastServicesData] = useState<any>([]);
  const [lastServicesIncludes, setLastSetvicesIncludes] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [monitorProducts, setMonitorProducts] = useState<any>([]);
  const [resultMeta, setResultMeta] = useState<Meta>();
  const scrollableWrapper = document.getElementById(
    "monitor-details"
  ) as HTMLElement;
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [isFetching, setIsFetching] = useInfiniteScroll(
    scrollableWrapper,
    hasMore
  );

  useEffect(() => {
    setDocuments([]);
    setLastServicesData([]);
    setLastSetvicesIncludes([]);
    setPage(1);
  }, [isPinSelected, selectedArea, monitorDetails, selectedProperty]);

  useEffect(() => {
    if (isFetching) {
      setPage((prevPage) => prevPage + 1);
      setIsFetching(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (resultMeta) {
      setHasMore(resultMeta?.currentPage < resultMeta?.totalPages);
    }
  }, [resultMeta]);

  useEffect(() => {
    const idNotChanged = prevMonitorId.current == monitorDetails.id;
    if (idNotChanged) {
      FetchMonitorLastServices(monitorDetails.id);
    }
  }, [page]);

  useEffect(() => {
    getProperties();
  }, [selectedProperty]);

  const getProperties = async () => {
    setLoading(true);
    setLoadingDetails(true);
    setMessage("");
    await PropertyService.GetProperty(selectedProperty)
      .then((property) => {
        const areas = property?.data?.propertyAreas?.map((propertyArea) => ({
          id: propertyArea.id,
          name: propertyArea.label,
          map: propertyArea.map,
          monitors: propertyArea.includes.monitors,
        }));

        setPropertyAreas(areas);
        if (areas.length === 0) {
          setMessage("Für dieses Objekt gibt es keine Bereiche");
        } else {
          const dynamicButtons = areas.map((area) => area.name);
          setSubMenuButtons(dynamicButtons);
          setCurrentTab(areas[0].name);
          changeMap(0, areas);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingDetails(false);
      });
  };

  const changeMap = (i: number, areas: PropertyArea[] | null = null) => {
    const propertyAreasToUse = areas || propertyAreas;
    setLoading(true);
    setMessage("");

    if (!propertyAreasToUse[i]?.map) {
      setMessage("Keine Karte hochgeladen");
    } else {
      setOpenMonitorDetails(true);
      setMap(propertyAreasToUse[i].map);
      if (propertyAreasToUse[i].monitors.length > 0) {
        setSelectedArea(i);

        const mappedCrops = propertyAreasToUse[i].monitors.map(
          (monitor: any) => ({
            x: Number(monitor.x),
            y: Number(monitor.y),
          })
        );

        const mappedMonitorPinAbbrs = propertyAreasToUse[i].monitors.map(
          (monitor: any) => ({
            name: monitor.referenceNumber.slice(0, 3).toUpperCase(),
            number: (monitor.isTemporary ? "T" : '') + monitor.position.toString(),
          })
        );

        setCrops(mappedCrops);
        setMonitorPinAbbrs(mappedMonitorPinAbbrs);
        handlePinClick(0, i, areas); //pokazi prvi u nizu monitora
        setIsPinSelected(true); //isPinSelected se salje kada zelimo prvi defultni monitor da oznacimo
      } else {
        setOpenMonitorDetails(false);
        setCrops([{x: 1, y: 1}]);
        setMonitorPinAbbrs([{name: "", number: ""}]);
      }
    }
    setLoading(false);
  };

  const handleChangeTab = (tab: any, i: any) => {
    setCurrentTab(tab);
    setMap(null);
    setCrops([]);
    setMonitorPinAbbrs([]);
    setIsPinSelected(true);
    setTimeout(() => {
      changeMap(i);
    }, 0);
  };

  const handlePinClick = async (
    index: number,
    area: number | null = null,
    areas: PropertyArea[] | null = null
  ) => {
    const indexArea = typeof area === "number" ? area : selectedArea;

    const propertyAreasToUse = areas || propertyAreas;

    const monitor = propertyAreasToUse[indexArea].monitors[index];
    const isIdChanged = prevMonitorId.current != monitor.id;
    if (isIdChanged) {
      prevMonitorId.current = monitor.id;
      setMonitorDetails({
        id: monitor.id,
        name: monitor.monitorType.label,
        number: monitor.position,
        location: monitor.location,
        isTemporary: monitor.isTemporary,
      });
      try {
        setLoadingDetails(true);
        const resultProducts: any =
          await MonitorService.GetMonitorCurrentProducts(monitor.id);

        if (resultProducts && Array.isArray(resultProducts.data)) {
          setMonitorProducts(resultProducts.data);
        }
        setDocuments([]);
        setLastServicesData([]);
        setLastSetvicesIncludes([]);
        FetchMonitorLastServices(monitor.id);
        setPage(1);
        setLoadingDetails(false);
        setOpenMonitorDetails(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const FetchMonitorLastServices = async (id: string) => {
    if (id != "") {
      const isIdChanged = prevMonitorId.current != id;
      prevMonitorId.current = id;
      const result: any = await MonitorService.GetMonitorLastServices(
        id,
        isIdChanged ? 1 : page,
        10
      );

      if (result && Array.isArray(result.data)) {
        setLastServicesData((prevData: any) => [
          ...(prevData ?? []),
          ...(result.data ?? []),
        ]);

        setLastSetvicesIncludes((prevData: any) => [
          ...(prevData ?? []),
          ...(result.includes?.job ?? []),
        ]);
        setDocuments((prevData: any) => [
          ...(prevData ?? []),
          ...(result.includes?.documents ?? []),
        ]);
        setResultMeta(result.meta);
      }
    }
  };

  return (
    <AppLayout>
      <div className={styles["customer-monitors"]}>
        <div className={styles["customer-monitors__heading"]}>
          <p className={styles["customer-monitors__title"]}>
            <MonitorsIcon /> Monitore
          </p>
        </div>

        <div className={styles["tabs"]}>
          <div className={styles["buttons"]}>
            {subMenuButtons.length > 0 &&
              subMenuButtons.map((button, i) => (
                <SubMenuButton
                  currentTab={currentTab}
                  value={button}
                  onSetTab={() => handleChangeTab(button, i)}
                  key={i}
                />
              ))}
          </div>
        </div>
      </div>

      {!loading &&
        map &&
        crops.length > 0 &&
        monitorPinAbbrs.length > 0 &&
        message === "" && (
          <div className={styles["image-container"]}>
            <div className={styles["image"]}>
              <PinCropMap
                map={map}
                crop={crops}
                monitorPinAbbr={monitorPinAbbrs}
                onCropChange={() => { }}
                isDraggable={true}
                onPinClick={handlePinClick}
                isPinSelected={isPinSelected}
              />
            </div>

            {openMonitorDetails && (
              <MonitorDetails
                monitorDetails={monitorDetails}
                lastServicesData={lastServicesData}
                lastServicesIncludes={lastServicesIncludes}
                documents={documents}
                monitorProducts={monitorProducts}
                loading={loadingDetails}
                isFetching={isFetching}
              />
            )}
          </div>
        )}

      {loading && <LoadingSpinner loading={loading} size={30} />}
      {message !== "" && (
        <NoData>
          <p>{message}</p>
        </NoData>
      )}
    </AppLayout>
  );
};
