import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackEndService from "../../api/BackEndService";
import MonitorServiceAction from "../../api/MonitorService";
import SettingsService from "../../api/SettingsService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { AnimalFound } from "../../components/IssuesType/AnimalFound";
import { BaitEaten } from "../../components/IssuesType/BaitEaten";
import { ServiceInfoBox } from "../../components/ServiceInfoBox/ServiceInfoBox";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { creatingServiceActions } from "../../store/job-slice";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { productSliceActions } from "../../store/products-slice";
import { MonitorEvent } from "../../store/serviced-monitors";
import { settingsActions } from "../../store/settings-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/issues-service.module.css";
import { ResponseMeta, ResponseWarningQueries } from "../../types/response";
import { InfestationType, Pests } from "../../types/service";
import { filterProducts } from "../../utils/filterProducts";

export const IssuesService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [monitorEventId, setMonitorEventId] = useState("");
  const [activeTab, setActiveTab] = useState<InfestationType>("baitEaten");
  const [requestLoading, setRequestLoading] = useState(false);
  const [error, setError] = useState("");

  const appointment = useSelector((state: RootState) => state.appointment);
  const service = useSelector((state: RootState) => state.service);
  const products = useSelector((state: RootState) => state.products);
  const issuesTab = useSelector((state: RootState) => state.tabs.issuesTabs);
  const servicedMonitors = useSelector(
    (state: RootState) => state.servicedMonitors.servicedMonitors
  );
  const monitor = useSelector((state: RootState) => state.monitor.monitor);
  const monitorEvents = useSelector(
    (state: RootState) => state.servicedMonitors.monitorEvents
  );
  const [imageLoading, setImageLoading] = useState(false);
  const fetchPests = useCallback(async () => {
    try {
      const monitorType = await MonitorServiceAction.GetMonitorTypeById(
        monitor.monitorTypeId
      );
      const _pests = await SettingsService.GetPests();
      const pests = _pests.data.filter((pest) =>
        monitorType.data.pests?.find((type: any) => type.id === pest.id)
      );
      dispatch(settingsActions.setPests({ pests: pests }));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const resetValues = () => {
    dispatch(creatingServiceActions.resetState());
    dispatch(productSliceActions.resetState());
    changeActiveTab("baitEaten");
  };
  const returnToDashboard = useCallback(() => {
    dispatch(activeTabsActions.setDashbaordUrl(""));
    dispatch(activeTabsActions.setMonitorsStep(2));
    resetValues();

    navigate(`/properties/${appointment.id}/show`);
  }, [appointment, dispatch, navigate]);

  const { sevDeskProducts, usedProducts } = filterProducts(products);

  const createMonitorEvent = useCallback(async () => {
    try {
      setRequestLoading(true);
      const monitorServiced = servicedMonitors?.includes(monitor!.id);

      let response: { data: MonitorEvent } & ResponseMeta &
        ResponseWarningQueries;
      const payload = {
        monitorId: monitor!.id,
        eventType: service.eventType,
        infestation: activeTab,
        ...(activeTab === "baitEaten" && {
          eatenQuantity: service.eatenQuantity,
        }),
        jobId: appointment?.jobs?.slice(-1).pop().id,
        ...(!monitorServiced && {
          pests: service.pests.filter((pest: Pests) => pest.quantity !== 0),
        }),
        comment: service.comment,
        usedProducts: usedProducts,
        sevdesk_products: sevDeskProducts,
      };

      if (monitorServiced) {
        response = await MonitorServiceAction.UpdateMonitorEvent(
          monitorEventId,
          payload
        );
      } else {
        response = await MonitorServiceAction.CreateMonitorEvent(payload);
      }

      if (response.data) {
        if (service.images.length > 0) {
          const imageUploadPromises = service.images.map((image) => {
            return BackEndService.AddDocument({
              documentType: "userUpload",
              fileName: image.name,
              isInternal: true,
              media: image.uuid,
              attachedTo: {
                type: "MonitorEvent",
                id: response.data.id,
              },
            });
          });

          // Wait for all image uploads to complete before proceeding
          await Promise.all(imageUploadPromises).then(() => response);
          setRequestLoading(false);
        }
        returnToDashboard();
      }
    } catch (error) {
      setRequestLoading(false);
      setError(() => t("MonitorIsRemoved"));
      console.log(error);
    }
  }, [
    usedProducts,
    sevDeskProducts,
    monitor,
    service.eventType,
    service.eatenQuantity,
    service.pests,
    service.comment,
    activeTab,
    appointment?.jobs,
    dispatch,
    service.images.length,
    returnToDashboard,
    servicedMonitors,
  ]);

  const changeActiveTab = (value: InfestationType) => {
    setActiveTab(value);
    dispatch(creatingServiceActions.resetState());
    dispatch(productSliceActions.resetState());
    dispatch(activeTabsActions.setIssuesTab(value));
  };
  const handleClockClick = () => {
    resetValues();
    dispatch(creatingMonitorActions.resetState());
  };

  useEffect(() => {
    fetchPests();
  }, [fetchPests]);

  useEffect(() => {
    setActiveTab(issuesTab);
  }, [issuesTab]);

  const handleBackButtonClicked = () => {
    goOneStepBack(navigate);

    resetValues();
    dispatch(creatingMonitorActions.resetState());
  };

  const handleLoadingChange = (loading: boolean) => {
    setImageLoading(loading);
  };

  useEffect(() => {
    setMonitorEventId(
      monitorEvents.find(
        (event: MonitorEvent) => event.monitorId === monitor.id
      )?.id ?? ""
    );
  }, [monitorEvents]);

  return (
    <AppLayout hideHeader>
      <BackButtonHeader
        handleBackButtonClicked={handleBackButtonClicked}
        showDocumentation={false}
      />

      <AppointmentDetails handleClockClickReset={handleClockClick} />

      <div className={styles["content-wrapper"]}>
        <ServiceInfoBox data={monitor} typeOfService="Issues" color="red" />

        <div className={styles["services__issue-type"]}>
          <p className={styles["services__issue-type--title"]}>
            {t("TypeIssue")}
          </p>

          <div className={styles["services__issue-type__buttons"]}>
            <div
              className={`${styles["services__issue-type__button"]} ${
                activeTab === "baitEaten" &&
                styles["services__issue-type__button--active"]
              }`}
              onClick={() => changeActiveTab("baitEaten")}
            >
              {t("BaitEaten")}
            </div>

            <div
              className={`${styles["services__issue-type__button"]} ${
                activeTab === "animalFound" &&
                styles["services__issue-type__button--active"]
              }`}
              onClick={() => changeActiveTab("animalFound")}
            >
              {t("AnimalFound")}
            </div>
          </div>
        </div>

        {activeTab === "baitEaten" ? (
          <BaitEaten
            monitorEventId={monitorEventId}
            redirect={returnToDashboard}
            onLoadingChange={handleLoadingChange}
          />
        ) : (
          <AnimalFound onLoadingChange={handleLoadingChange} />
        )}

        {error && <div className={styles["error-message"]}>{error}</div>}

        <FloatingButtons>
          <CustomButton
            color="green"
            onClick={createMonitorEvent}
            width={60}
            disabled={imageLoading}
            loading={requestLoading}
          >
            {t("Confirm")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
