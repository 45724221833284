import { MouseEventHandler } from "react";

import uploadDocument from "./upload_document_icon_dark.png";

type UploadDocumentIconProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const UploadDocumentIcon = ({ onClick }: UploadDocumentIconProps) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <img src={uploadDocument} alt="Upload document icon" />
    </div>
  );
};
