import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classNames from "classnames";
import { MouseEventHandler, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NoConnectionModal } from "../../components/NoConnectionModal/NoConnectionModal";
import { useScreenSize } from "../../hooks/useScreenSize";
import { RootState } from "../../store";
import { PropertyInfo, userSliceActions } from "../../store/user-slice";

import styles from "./header.module.css";

type HeaderProps = {
  withBackButton?: boolean;
  isMinimized?: boolean;
  onClick: Function;
  onGoBack?: MouseEventHandler<HTMLImageElement>;
};

export const CustomerHeader = ({
  withBackButton,
  isMinimized,
  onClick,
  onGoBack,
}: HeaderProps) => {
  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();

  const [showNoConnectionModal, setShowNoConnectionModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const currentUser = useSelector((state: RootState) => state.user);

  const properties = useSelector((state: RootState) => state.user.properties);
  const selectedProperty = useSelector(
    (state: RootState) => state.user.selectedProperty
  );

  const handleCloseModal = () => {
    setShowNoConnectionModal(false);
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelectProperty = (property: PropertyInfo) => {
    dispatch(userSliceActions.setSelectedPropertyId(property.id));
    dispatch(userSliceActions.setSelectedProperty(property));
    setShowDropdown(false);
  };

  return (
    <>
      <div
        onClick={() => onClick()}
        className={classNames(
          styles["header"],
          {
            [styles["customer-header"]]: !showDropdown,
          },
          {
            [styles["desktop-header"]]: isDesktop,
          },
          {
            [styles["header--minimized"]]: isMinimized,
          },
          {
            [styles["header--dropdown"]]: showDropdown,
          }
        )}
      >
        <div
          className={`${styles["header__actions"]} ${styles["customer-header__actions"]}`}
        >
          {withBackButton && (
            <img alt="" src="/img/return-arrow.png" onClick={onGoBack} />
          )}

          {!withBackButton && (
            <div className={styles["header__profile-picture"]} />
          )}
          <div>
            <p className={styles["header__profile-name"]}>
              {currentUser.profileName}
            </p>
          </div>
          <div>
            <p
              className={styles["header__profile-property"]}
              onClick={(e) => {
                e.stopPropagation();
                handleToggleDropdown();
              }}
            >
              {selectedProperty?.street ?? ""}{" "}
              {selectedProperty?.postcode ?? ""} {selectedProperty?.city ?? ""}
            </p>
          </div>
          <div className={styles["header__dropdown"]}>
            {properties && properties.length > 0 && (
              <ArrowDropDownIcon onClick={(e) => {
                e.stopPropagation();
                handleToggleDropdown();
              }} />
            )}
          </div>
        </div>
        {showNoConnectionModal && (
          <NoConnectionModal onClose={handleCloseModal} />
        )}
      </div>
      {showDropdown && (
        <div className={styles["dropdown-menu"]}>
          {properties &&
            properties.map((property: PropertyInfo) => (
              <div
                key={property.id}
                className={styles["dropdown-item"]}
                onClick={() => handleSelectProperty(property)}
              >
                {property.street} {property.postcode} {property.city}
              </div>
            ))}
        </div>
      )}
    </>
  );
};
